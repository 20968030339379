import React from 'react';
import HeroSection from '../../Components/HeroSection'; 
import { useTranslation } from 'react-i18next';
import ImageGeneral from '../../Images/Produit/General.webp';
import ImageFinance from '../../Images/Produit/Finance.webp';
import ImageAdmin from '../../Images/Produit/Admin.webp';
import ImageDomicile from '../../Images/Produit/Domicile.webp';
import ImageEco from '../../Images/Produit/Eco.webp';
import ImagePerso from '../../Images/Produit/Perso.webp';
import ImagePro from '../../Images/Produit/Pro.webp';
import ImageSocial from '../../Images/Produit/Social.webp';
import ImageTime from '../../Images/Produit/Time.webp';
import { Helmet } from 'react-helmet';


const Produit = () => {
  const { t } = useTranslation('produit');
  
  const pageData = [
    {
      title: t('produit.generalTitle'),
      subtitle: t('produit.generalSubtitle'),
      buttonText: t('produit.buttonText'),
      buttonLink: "/Solution/Présentation-solution",
      backgroundImage: ImageGeneral
    },
    {
      title: t('produit.financesTitle'),
      subtitle: t('produit.financesSubtitle'),
      buttonText: t('produit.buttonText'),
      buttonLink: "/Solution/Gérez-vos-finances",
      backgroundImage: ImageFinance
    },
    {
      title: t('produit.timeTitle'),
      subtitle: t('produit.timeSubtitle'),
      buttonText: t('produit.buttonText'),
      buttonLink: "/Solution/Gérez-votre-temps",
      backgroundImage: ImageTime
    },
    {
      title: t('produit.socialTitle'),
      subtitle: t('produit.socialSubtitle'),
      buttonText: t('produit.buttonText'),
      buttonLink: "/Solution/Gérez-vos-relations",
      backgroundImage: ImageSocial
    },
    {
      title: t('produit.careerTitle'),
      subtitle: t('produit.careerSubtitle'),
      buttonText: t('produit.buttonText'),
      buttonLink: "/Solution/Gérez-votre-carrière-pro",
      backgroundImage: ImagePro
    },
    {
      title: t('produit.personalLifeTitle'),
      subtitle: t('produit.personalLifeSubtitle'),
      buttonText: t('produit.buttonText'),
      buttonLink: "/Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
      backgroundImage: ImagePerso
    },
    {
      title: t('produit.administrativeTitle'),
      subtitle: t('produit.administrativeSubtitle'),
      buttonText: t('produit.buttonText'),
      buttonLink: "/Solution/Gérez-votre-vie-administrive",
      backgroundImage: ImageAdmin
    },
    {
      title: t('produit.environmentTitle'),
      subtitle: t('produit.environmentSubtitle'),
      buttonText: t('produit.buttonText'),
      buttonLink: "/Solution/Gérez-votre-empreinte-carbone",
      backgroundImage: ImageEco
    },
    {
      title: t('produit.homeTitle'),
      subtitle: t('produit.homeSubtitle'),
      buttonText: t('produit.buttonText'),
      buttonLink: "/Solution/Gérez-votre-immobilier-et-vos-véhicules",
      backgroundImage: ImageDomicile
    }
  ];

  return (
    <>
      <Helmet>
          <title>{t('produit.pageTitle')}</title>
          <meta name="title" content={t('produit.pageMetaTitle')}/>
          <meta name="description" content={t('produit.pageMetaDescription')} />
          <link rel="canonical" href="https://be-lifer.com/Solution"/>
      </Helmet>
      <div>
        {pageData.map((data, index) => (
          <HeroSection 
            key={index}
            className="heroSection"
            style={{ zIndex: 10 }}
            title={data.title}
            subtitle={data.subtitle}
            buttonText={data.buttonText}
            backgroundImage={data.backgroundImage}
            buttonLink={data.buttonLink}
          />
        ))}
      </div>
    </>
  );
};

export default Produit;
