import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import Slider from 'react-slick';
import '../Style/Accueil.css';

const Carousel = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: "linear"
  };

  return (
    <div className="carouselContainer"> 
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            {slide.type === 'video' ? (
              <video autoPlay loop muted playsInline className="carouselContainer">
                <source src={process.env.PUBLIC_URL + slide.src} type="video/mp4" />
                Votre navigateur ne supporte pas les vidéos HTML5.
              </video>
            ) : (
              <img src={process.env.PUBLIC_URL + slide.src} className="carousel-image" alt={`Slide ${index + 1}`} />
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
