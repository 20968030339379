import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/Produit/Time.webp';
import TestBanner from '../../Components/TestBanner';
import Features from '../../Components/FeatureSection';
import BackTime from '../../Images/Produit/TimeScreen.webp';
import slide1 from '../../Images/Produit/TimeScreen2.webp'; 
import slide2 from '../../Images/Produit/TimeScreen3.webp'; 
import Accordion from '../../Components/Accordion';
import TextBanner from '../../Components/TextBanner';
// import SliderComponent from '../../Components/SliderComponent';
import HeroBanner from '../../Components/HeroBanner';
import Carousel from '../../Components/Carousel';
import ImageDomicile from '../../Images/Produit/Domicile.webp';
import ImageFinance from '../../Images/Produit/Finance.webp';
import ImageAdmin from '../../Images/Produit/Admin.webp';
import ImageEco from '../../Images/Produit/Eco.webp';
import ImagePerso from '../../Images/Produit/Perso.webp';
import ImagePro from '../../Images/Produit/Pro.webp';
import ImageSocial from '../../Images/Produit/Social.webp';
import ImageGeneral from '../../Images/Produit/General.webp';
import MonCarrousel from '../../Components/MonCarrousel';
import Advantages from '../../Components/Advantage';
import Coherance from '../../Images/Produit/Coherance.webp';
import Efficacite from '../../Images/Produit/Efficacite.webp';
import Clarte from '../../Images/Produit/Clarte.webp';
import '../../Style/Produit.css';
import { Helmet } from 'react-helmet';

const Time = () => {
  const advantagesList = [
    {
      icon: Coherance,
      title: 'Cohérance',
      description: 'Maîtrisez votre temps avec des rappels intelligents qui préviennent les oublis et sécurisent vos engagements.',
    },
    {
      icon: Efficacite,
      title: 'Efficacité',
      description: 'Centralisez tâches et événements pour une efficacité accrue sans jongler entre applications.',
    },
    {
      icon: Clarte,
      title: 'Clarté',
      description: 'Des tableaux de bord et rapports précis pour un contrôle total sur la gestion du temps, optimisant ainsi votre prise de décision.',
    },
  ];

  const pageData = {
    title: 'NOS AUTRES SOLUTIONS',
    items: [
      {
        imageUrl: ImageSocial,
        title: 'Les relations sociales',
        link: '/Solution/Gérez-vos-relations'
      },
      {
        imageUrl: ImageAdmin,
        title: "L'administrative",
        link: '/Solution/Gérez-votre-vie-administrive'
      },
      {
        imageUrl: ImagePro,
        title: 'La carrière pro',
        link: '/Solution/Gérez-votre-carrière-pro'
      },
      {
        imageUrl: ImagePerso,
        title: 'La vie perso',
        link: '/Solution/Gérez-votre-santé-sport-alimentation-spiritualité'
      },
      {
        imageUrl: ImageEco,
        title: 'Notre envirronnement',
        link: '/Solution/Gérez-votre-empreinte-carbone'
      },
      {
        imageUrl: ImageDomicile,
        title: 'Le domicile',
        link: '/Solution/Gérez-votre-immobilier-et-vos-véhicules'
      },
      {
        imageUrl: ImageFinance,
        title: "Les finances",
        link: '/Solution/Gérez-vos-finances'
      },
      {
        imageUrl: ImageGeneral,
        title: "L'intégration",
        link: '/Solution'
      },
    ]
  };

  const featureData = [
    {
      title: "Agenda Personnel",
      content: "Créez un emploi du temps quotidien, hebdomadaire ou mensuel en ajoutant facilement événements, tâches ou rendez-vous grâce à une interface utilisateur intuitive, offrant une visualisation claire et des modifications aisées. Intégrez des techniques telles que la matrice Eisenhower pour hiérarchiser et gérer les tâches urgentes et importantes.",
      link: "/Solution/Gérez-votre-temps",
    },
    {
      title: "Intégration Financière",
      content: "Liez vos engagements financiers à votre emploi du temps. Intégrez des dates d'échéance de factures à votre agenda ou planifiez des achats majeurs avec des rappels pour économiser ou vérifier vos finances, et apprenez à gérer votre budget de façon à optimiser chaque heure de votre journée de travail.",
      link: "/Solution/Gérez-votre-temps",
    },
    {
      title: "Connecteur Social",
      content: "Synchronisez votre agenda avec des plateformes sociales ou des applications de contacts pour intégrer des événements sociaux, anniversaires et réunions, permettant une vue consolidée de vos engagements personnels et sociaux. Gérez efficacement les interruptions pour rester productif.",
      link: "/Solution/Gérez-votre-temps",
    },
    {
      title: "Assistant de Notification",
      content: "Recevez des rappels automatisés pour vos événements, tâches et rendez-vous, personnalisables en termes de fréquence, sonnerie ou type de notification, assurant que vous ne manquez aucun engagement important. Utilisez des méthodes comme la technique Pomodoro pour vous concentrer et minimiser les perturbations.",
      link: "/Solution/Gérez-votre-temps",
    },
    {
      title: "Pilote de Projet",
      content: "Créez des projets avec des objectifs définis et des échéances, subdivisez-les en tâches spécifiques, et organisez ces tâches en listes avec des priorités, dépendances et échéances. Suivez l'avancement global du projet avec une vue de progression et soyez proactif face aux imprévus.",
      link: "/Solution/Gérez-votre-temps",
    },
    {
      title: "Liste Évolutive",
      content: "Gérez des tâches quotidiennes à l'aide de listes indépendantes pour des courses, tâches ménagères ou autres, avec la possibilité de cocher ou décocher les éléments accomplis. Améliorez votre capacité à organiser et gérer vos priorités quotidiennes pour optimiser votre temps.",
      link: "/Solution/Gérez-votre-temps",
    },
    {
      title: "Bloc-Notes Dynamique",
      content: "Prenez des notes, des idées ou des informations rapidement, les associant à des projets, tâches ou les gardant indépendantes. Utilisez des fonctionnalités de recherche, de classement par tags ou par date, et d'épinglage pour une organisation et un accès faciles. Gérez votre temps pour les tâches intellectuelles avec une efficacité accrue.",
      link: "/Solution/Gérez-votre-temps",
    },
    {
      title: " Traceur d'Objectifs",
      content: "Définissez des objectifs à court, moyen ou long terme, liez-les à des projets, tâches ou engagements personnels, et suivez leur avancement avec des jalons ou des critères de succès. Recevez des notifications ou des rappels pour rester concentré et motivé, en évitant la procrastination et en étant efficace dans l'utilisation de chaque minute de votre journée.",
      link: "/Solution/Gérez-votre-temps",
    },
    {
      title: " Dashboard temps",
      content: "Accédez à des rapports consolidés sur vos projets, tâches et objectifs à travers des graphiques et tableaux clairs. Personnalisez le reporting selon vos besoins, que ce soit pour des analyses hebdomadaires ou des rapports mensuels sur les objectifs atteints. Ce tableau de bord vous aide à surveiller votre efficacité, à identifier rapidement les zones nécessitant des ajustements, et à rester proactif grâce à des alertes personnalisées pour les deadlines importantes et les succès. Utilisez ces informations pour optimiser vos processus et maximiser votre productivité.",
      link: "/Solution/Gérez-votre-temps",
    },
  ];

  const slides = [
    { src: slide1, type: 'image' },
  ];

  const slides2 = [
    { src: slide2, type: 'img' },
  ];

    return (
      <div>
        <Helmet>
          <title>Be-lifer, Optimisez Votre Gestion du Temps avec Notre Solution Innovante</title>
          <meta name="title" content="Be-lifer, Optimisez Votre Gestion du Temps avec Notre Solution Innovante"/>
          <meta name="description" content="Découvrez comment maximiser votre efficacité quotidienne grâce à nos outils de gestion du temps personnalisés, incluant un agenda personnel, une intégration financière, un connecteur social, et plus encore. Gérez mieux votre temps et augmentez votre productivité." />
          <link rel="canonical" href="https://be-lifer.com/Solution/Gérez-votre-temps" />
          <meta name="author" content="Be-lifer"/>
          <meta name="robots" content="index, follow"/>
          <meta name="author" content="Votre Nom ou le Nom de votre Entreprise"/>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
          <meta name="language" content="French"/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content="Be-Lifer : Optimisez Votre Gestion du Temps"/>
          <meta property="og:description" content="Découvrez comment maximiser votre efficacité quotidienne grâce à nos outils de gestion du temps personnalisés, incluant un agenda personnel, une intégration financière, un connecteur social, et plus encore. Gérez mieux votre temps et augmentez votre productivité."/>
          <meta property="og:url" content="https://www.be-lifer.com/Solution/Gérez-votre-temps"/>
          <meta property="og:site_name" content="Be-Lifer"/>
      @</Helmet>
        <Encart 
          title="Le temps" 
          content="L'usage du temps est l'ultime expression de l'art de vivre et de l'authenticité personnelle. Nos moments choisis et savourés incarnent un luxe inégalable, tissant le tissu de notre existence avec une précision et une qualité sans pareilles dans l'éphémère de la vie. Savoir gérer son temps est donc essentiel pour maximiser notre efficacité et pour que chaque instant compte." 
          image={Image}
        />
        <TestBanner />
        <TextBanner
          title = "Optimisez votre temps"
          content = "Maximisez la gestion de votre temps en planifiant efficacement et en intégrant harmonieusement d'autres modules. Organisez vos journées pour aligner vos objectifs personnels et professionnels, tout en conservant une vue d'ensemble sur vos engagements. Gérez votre temps efficacement, réduisez le stress lié à la gestion du quotidien et libérez du temps pour ce qui compte vraiment pour vous. Cela contribue à une meilleure qualité de vie et à une empreinte personnelle positive sur votre environnement. Apprenez à dire non aux distractions et à prioriser vos activités pour augmenter votre productivité."
          level = "h2"
        />
        
        <Carousel slides={slides} />
        <Advantages advantagesList={advantagesList} level="h3" /> 
        <TestBanner />
        <Carousel slides={slides2} />
        <TestBanner />
        <h4>Des solutions conçues pour vous</h4>
        <Features features={featureData} />
        <TestBanner />
        <HeroBanner
          title="Maîtrisez votre temps"
          subtitle="Maximisez votre potentiel"
          buttonText="Démarrer gratuitement"
          buttonLink="https://be-lifer.com/rejoignez-nous/Devenez-Testeur"
          backgroundImage={BackTime}
        />
        
        <MonCarrousel title={pageData.title} items={pageData.items} visibleItemsCount={3}  />
      </div>
    );
};

export default Time;