import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import commonEN from './locales/en/common.json';
import accueilEN from './locales/en/accueil.json';
import headerEN from './locales/en/header.json'
import generalEN from './locales/en/general.json'
import produitEN from './locales/en/produit.json'
import testbannerEN from './locales/en/testbanner.json'
import solutionEN from './locales/en/solution.json'
import roadmapEN from './locales/en/roadmap.json'
import aboutEN from './locales/en/about.json'

import commonFR from './locales/fr/common.json';
import accueilFR from './locales/fr/accueil.json';
import headerFR from './locales/fr/header.json'
import generalFR from './locales/fr/general.json'
import produitFR from './locales/fr/produit.json'
import testbannerFR from './locales/fr/testbanner.json'
import solutionFR from './locales/fr/solution.json'
import roadmapFR from './locales/fr/roadmap.json'
import aboutFR from './locales/fr/about.json'

import commonDE from './locales/de/common.json';
import accueilDE from './locales/de/accueil.json';
import headerDE from './locales/de/header.json'
import generalDE from './locales/de/general.json'
import produitDE from './locales/de/produit.json'
import testbannerDE from './locales/de/testbanner.json'
import solutionDE from './locales/de/solution.json'
import roadmapDE from './locales/de/roadmap.json'
import aboutDE from './locales/de/about.json'

import commonIT from './locales/it/common.json';
import accueilIT from './locales/it/accueil.json';
import headerIT from './locales/it/header.json'
import generalIT from './locales/it/general.json'
import produitIT from './locales/it/produit.json'
import testbannerIT from './locales/it/testbanner.json'
import solutionIT from './locales/it/solution.json'
import roadmapIT from './locales/it/roadmap.json'
import aboutIT from './locales/it/about.json'


i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      fr: { 
        common: commonFR,
        accueil: accueilFR,
        header : headerFR,
        general : generalFR,
        produit: produitFR,
        testbanner: testbannerFR,
        solution: solutionFR,
        roadmap: roadmapFR,
        about : aboutFR
      },
      en: {
        common: commonEN,
        accueil: accueilEN,
        header : headerEN,
        general : generalEN,
        produit: produitEN,
        testbanner: testbannerEN,
        solution: solutionEN,
        roadmap: roadmapEN,
        about : aboutEN
      },
      de: { 
        common: commonDE,
        accueil: accueilDE,
        header : headerDE,
        general : generalDE,
        produit: produitDE,
        testbanner: testbannerDE,
        solution: solutionDE,
        roadmap: roadmapDE,
        about : aboutDE
      },
      it: { 
        common: commonIT,
        accueil: accueilIT,
        header : headerIT,
        general : generalIT,
        produit: produitIT,
        testbanner: testbannerIT,
        solution: solutionIT,
        roadmap: roadmapIT,
        about : aboutIT
      },
      'fr-CH': {
        common: commonFR,
        accueil: accueilFR,
        header : headerFR,
        general : generalFR,
        produit: produitFR,
        testbanner: testbannerFR,
        solution: solutionFR,
        roadmap: roadmapFR,
        about : aboutFR
      },
      'de-CH': { 
        common: commonDE,
        accueil: accueilDE,
        header : headerDE,
        general : generalDE,
        produit: produitDE,
        testbanner: testbannerDE,
        solution: solutionDE,
        roadmap: roadmapDE,
        about : aboutDE
      },
    },
    lng: 'fr', // Langue par défaut
    fallbackLng: 'en', // Langue de repli
    interpolation: {
      escapeValue: false, // React déjà fait l'échappement
    },
    react: {
      useSuspense: true, // Utilise le suspense pour le chargement des traductions
    },
  });

export default i18n;
