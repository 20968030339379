import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/Produit/Perso.webp';
import TestBanner from '../../Components/TestBanner';
import Features from '../../Components/FeatureSection';
import slide1 from '../../Images/Produit/PersoScreen.webp'; 
import BackPerso from '../../Images/Produit/PersoBack.webp';
import slide2 from '../../Images/Produit/PersoScreen2.webp'; 
import Accordion from '../../Components/Accordion';
import TextBanner from '../../Components/TextBanner';
import HeroBanner from '../../Components/HeroBanner';
import Carousel from '../../Components/Carousel';
import ImageTime from '../../Images/Produit/Time.webp';
import ImageDomicile from '../../Images/Produit/Domicile.webp';
import ImageFinance from '../../Images/Produit/Finance.webp';
import ImageAdmin from '../../Images/Produit/Admin.webp';
import ImageEco from '../../Images/Produit/Eco.webp';
import ImagePro from '../../Images/Produit/Pro.webp';
import ImageSocial from '../../Images/Produit/Social.webp';
import ImageGeneral from '../../Images/Produit/General.webp';
import MonCarrousel from '../../Components/MonCarrousel';
import Advantages from '../../Components/Advantage';
import Simplicite from '../../Images/Produit/Simplicité.webp';
import Equilibre from '../../Images/Produit/Equilibre.webp';
import Sante from '../../Images/Produit/Santé.webp';
import { Helmet } from 'react-helmet';
import '../../Style/Produit.css';

const Perso = () => {
  const advantagesList = [
    {
      icon: Simplicite,
      title: 'Simplicité',
      description: "Gérez votre bien-être sous toutes ses formes en un seul endroit pratique et intégré.",
    },
    {
      icon: Equilibre,
      title: 'Équilibre',
      description: "Favorisez un style de vie équilibré avec une alimentation saine, une activité physique régulière et des moments dédiés aux loisirs et à la spiritualité.",
    },
    {
      icon: Sante,
      title: 'Santé',
      description: "Améliorez votre santé globale en incorporant régulièrement des activités physiques, des loisirs et des soins personnels grâce à l'application.",
    },
  ];

  const pageData = {
    title: 'NOS AUTRES SOLUTIONS',
    items: [
      {
        imageUrl: ImageSocial,
        title: 'Les relations sociales',
        link: '/Solution/Gérez-vos-relations'
      },
      {
        imageUrl: ImageTime,
        title: 'Le temps',
        link: '/Solution/Gérez-votre-temps'
      },
      {
        imageUrl: ImageAdmin,
        title: "L'administrative",
        link: '/Solution/Gérez-votre-vie-administrive'
      },
      {
        imageUrl: ImagePro,
        title: 'La carrière pro',
        link: '/Solution/Gérez-votre-carrière-pro'
      },
      {
        imageUrl: ImageEco,
        title: 'Notre envirronnement',
        link: '/Solution/Gérez-votre-empreinte-carbone'
      },
      {
        imageUrl: ImageDomicile,
        title: 'Le domicile',
        link: '/Solution/Gérez-votre-immobilier-et-vos-véhicules'
      },
      {
        imageUrl: ImageFinance,
        title: "Les finances",
        link: '/Solution/Gérez-vos-finances'
      },
      {
        imageUrl: ImageGeneral,
        title: "L'intégration",
        link: '/Solution'
      },
    ]
  };

  const featureData = [
    {
      title: "Jardinier d'Intérieur ",
      content: "Ajoutez vos plantes à l'application, spécifiez leur type et emplacement. Recevez des conseils de soins personnalisés, des rappels pour l'arrosage, la fertilisation et le rempotage, et suivez la croissance de vos plantes avec des astuces pour traiter parasites et maladies.",
      link: "/Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
    },
    {
      title: "Explorateur Culinaire",
      content: "Découvrez des recettes variées selon vos préférences, ingrédients disponibles ou la saison. Filtrez les recettes par critères tels que végétarien ou sans gluten, et accédez à des instructions détaillées, photos et commentaires.",
      link: "/Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
    },
    {
      title: "Planificateur de Menu",
      content: "Créez des menus hebdomadaires ou pour occasions spéciales en sélectionnant vos recettes favorites. L'application génère une liste de courses basée sur ces recettes et vous rappelle la préparation ou l'achat d'ingrédients spécifiques.",
      link: "/Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
    },
    {
      title: "Sport & Bien-être",
      content: "Suivez vos routines d'exercice, définissez des objectifs de fitness, obtenez des conseils d'entraînement et surveillez vos progrès. Enregistrez également vos signes vitaux, rendez-vous médicaux et médicaments, tout en explorant des méthodes complémentaires de bien-être.",
      link: "/Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
    },
    {
      title: "Santé",
      content: "En plus de suivre vos routines d'exercice et objectifs de fitness, l'application vous permet de gérer votre santé de manière complète. Suivez vos consultations médicales, hospitalisations, traitements et médicaments. Recevez des rappels pour vos rendez-vous de santé, renouvellements de prescriptions, et gardez un historique détaillé de vos soins médicaux pour une gestion de santé proactive et organisée.",
      link: "/Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
    },
    {
      title: "Voyage Spirituel",
      content: "Accédez à des méditations guidées, lectures inspirantes et pratiques de respiration. Explorez différentes traditions spirituelles pour un bien-être holistique.",
      link: "/Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
    },
    {
      title: "Gestionnaire de Loisirs",
      content: "Planifiez vos hobbies, suivez vos lectures, films, projets d'artisanat et autres activités favorites. L'application vous aide à organiser et à profiter pleinement de vos loisirs.",
      link: "/Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
    },
    {
      title: "Dashboard personnel",
      content: "Gérez votre santé, loisirs, et maison avec notre tableau de bord intégré. Suivez votre fitness, santé médicale, entretien de plantes, planification de repas, et bien-être mental, tout en organisant vos hobbies, le tout en un seul endroit pour une gestion quotidienne simplifiée.",
      link: "/Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
    },
  ];

  const slides = [
    { src: slide1, type: 'img' },
  ];

  const slides2 = [
    { src: slide2, type: 'img' },
  ];

    return (
      <div>
        <Helmet>
          <title>Be-lifer, Harmonisez Votre Vie Quotidienne avec Life</title>
          <meta name="title" content="Be-lifer, Harmonisez Votre Vie Quotidienne avec Life"/>
          <meta name="description" content="Unifiez votre quotidien avec Life : de la méditation aux loisirs, tout pour une vie équilibrée et écologique." />
          <link rel="canonical" href="https://be-lifer.com/Solution/Gérez-votre-santé-sport-alimentation-spiritualité"/>
        </Helmet>
        <Encart 
          title="La vie personnelle" 
          content="La vie personnelle est un kaléidoscope de bien-être et d'épanouissement, où chaque aspect, qu'il s'agisse de la santé, du sport, des loisirs ou du spirituel, brille de sa propre lumière. Ces facettes ensemble forment un joyau d'authenticité et d'harmonie, reflétant un équilibre unique et précieux, façonné par nos choix et nos passions, et enrichissant notre existence de manière incomparable." 
          image={Image}
        />
        <TestBanner />
        <TextBanner
          title = "Enrichir Corps, Esprit et Âme : Un Outil Holistique pour une Vie Équilibrée"
          content = "Unifiez tous les aspects de votre quotidien avec une seule plateforme intuitive, transformant la complexité de la gestion de vie en une expérience fluide et agréable. De la planification de vos repas à vos séances de méditation, en passant par l'adoption de pratiques écologiques et l'organisation de vos loisirs, notre système est la clé d'une vie harmonieuse et équilibrée. Embrassez une existence enrichie, où chaque besoin personnel trouve sa place, orchestré avec soin pour un équilibre parfait entre bien-être et responsabilité environnementale."
        />
        
        <Carousel slides={slides} />
        <Advantages advantagesList={advantagesList} /> 
        <TestBanner />
        <Carousel slides={slides2} />
        <TestBanner />
        <h1>Des solutions conçues pour vous</h1>
        <Features features={featureData} />
        <TestBanner />
        <HeroBanner
          title="Nourrissez corps, esprit et âme,"
          subtitle="Épanouissez-vous pleinement"
          buttonText="Démarrer gratuitement"
          buttonLink="https://be-lifer.com/rejoignez-nous/Devenez-Testeur"
          backgroundImage={BackPerso}
        />
        
        <MonCarrousel title={pageData.title} items={pageData.items} visibleItemsCount={3}  />
      </div>
    );
};

export default Perso;