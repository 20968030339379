import React, { useEffect, useState } from 'react';
import '../Style/Banner.css';
import { Link } from 'react-router-dom';

const HeroSection = ({ title, subtitle, buttonText, backgroundImage, buttonLink }) => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div 
      className="hero-section" 
      style={{
        backgroundImage: `url(${backgroundImage})`
      }}
    >
      <div className="hero-content" >
      <div className={'titleProduct'}> {title}</div>
        <p>{subtitle}</p>
        <Link to={buttonLink} target="_blank" rek="noopener noreferrer">
          <button>{buttonText}</button>
        </Link>
      </div>
    </div>
  );
};

export default HeroSection;
