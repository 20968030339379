import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/About/Testeur.webp';
import TextBanner from '../../Components/TextBanner';
import ContactForm from '../../Components/ContactForm';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Testeur = () => {
  const { t } = useTranslation('about');
  const sendEmail = async (formData) => {
    try {
      // const response = await fetch('http://localhost:3002/send-email', {
      const response = await fetch('https://osqvaiaq1d.execute-api.us-east-1.amazonaws.com/prod/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Echec de l\'envoi de l\'email');
      }
  
      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email:', error);
    }
  };

  const RelationOptions = [
    { value: "Collaborateur1", text: t('testeur.relationOptions.0.text') }
  ];
  
  return (
    <>
      <Helmet>
        <title>{t('testeur.pageTitle')}</title>
        <meta name="title" content={t('testeur.pageMetaTitle')} />
        <meta name="description" content={t('testeur.pageMetaDescription')} />
        <link rel="canonical" href="https://be-lifer.com/rejoignez-nous/Devenez-Testeur" />
      </Helmet>
      <div>
        <Encart 
          title={t('testeur.encartTitle')}
          content={t('testeur.encartContent')}
          image={Image}
        />
        <TextBanner
          title={t('testeur.textBannerTitle1')}
          content={t('testeur.textBannerContent1')}
          level="h2" 
        />
        <TextBanner
          title={t('testeur.textBannerTitle2')}
          content={t('testeur.textBannerContent2')}
          level="h3" 
        />
        <ContactForm 
          defaultMessagePlaceholder={t('testeur.contactFormPlaceholder')}
          defaultRelation={t('testeur.contactFormDefaultRelation')}
          options={RelationOptions}
          sendEmail={sendEmail}
        />
      </div>
    </>
  );
};

export default Testeur;