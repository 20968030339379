import React, { useState } from 'react';
import TextBanner from '../../Components/TextBanner';
import YouTubeVideo from '../../Components/Youtubevideo';
import HeroBanner from '../../Components/HeroBanner';
import BackImg from '../../Images/Accueil/Background.webp';
import { Helmet } from 'react-helmet';
import Encart from '../../Components/Encart';
import Image from '../../Images/Produit/Technophile.webp';
import { useTranslation } from 'react-i18next';

import '../../Style/Produit.css';

const Techno = () => {
    const { t } = useTranslation('solution');

    return (
        <>
            <Helmet>
                <title>{t('technophile.pageTitle')}</title>
                <meta name="title" content={t('technophile.pageMetaTitle')}/>
                <meta name="description" content={t('technophile.pageMetaDescription')} />
                <link rel="canonical" href="https://be-lifer.com/Solution/Nos-solutions-pour-les-technophiles"/>
                <meta name="author" content="Be-lifer"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={t('technophile.pageMetaTitle')}/>
                <meta property="og:description" content={t('technophile.pageMetaDescription')}/>
                <meta property="og:url" content="https://be-lifer.com/Solution/Nos-solutions-pour-les-technophiles"/>
                <meta property="og:site_name" content="Be-Lifer"/>
            </Helmet>
            <div>
                <Encart 
                    title={t('technophile.encartTitle')} 
                    content={t('technophile.encartContent')} 
                    image={Image}
                />
                <TextBanner
                    title={t('technophile.textBannerTitle1')}
                    content={t('technophile.textBannerContent1')}
                    level="h2"
                />
                <TextBanner
                    title={t('technophile.textBannerTitle2')}
                    content={t('technophile.textBannerContent2')}
                    level="h3"
                />
                {/* <YouTubeVideo videoId="WR6OfQ-DLeI" /> */}
                <TextBanner
                    title={t('technophile.textBannerTitle3')}
                    content={t('technophile.textBannerContent3')}
                    level="h4"
                />
                {/* <YouTubeVideo videoId="Mdq-J1PGGfI" /> */}
                <TextBanner
                    title={t('technophile.textBannerTitle4')}
                    content={t('technophile.textBannerContent4')}
                    level="h5"
                />
                {/* <YouTubeVideo videoId="vvG89sHxO7w" /> */}
                <TextBanner
                    title={t('technophile.textBannerTitle5')}
                    content={t('technophile.textBannerContent5')}
                    level="h6"
                />
                <TextBanner
                    title={t('technophile.textBannerTitle6')}
                    content={t('technophile.textBannerContent6')}
                    level="h6"
                    className='h7'
                />
                <TextBanner
                    title={t('technophile.textBannerTitle7')}
                    content={t('technophile.textBannerContent7')}
                    level="h6"
                    className='h8'
                />
                <TextBanner
                    title={t('technophile.textBannerTitle8')}
                    content={t('technophile.textBannerContent8')}
                    level="h6"
                    className='h9'
                />
                <TextBanner
                    title={t('technophile.textBannerTitle9')}
                    content={t('technophile.textBannerContent9')}
                    level="h6"
                    className='h10'
                />
                <HeroBanner
                    title={t('technophile.heroBannerTitle')}
                    subtitle={t('technophile.heroBannerSubtitle')}
                    buttonText={t('technophile.heroBannerButtonText')}
                    buttonLink={t('technophile.heroBannerButtonLink')}
                    backgroundImage={BackImg}
                />
            </div>
        </>
    );
};

export default Techno;