import React from 'react';
import PropTypes from 'prop-types';
import '../Style/UsBanner.css';
import { Link } from 'react-router-dom';

const Card = ({ title, description, buttonText, buttonLink, imageUrl }) => (
  <div className="dashboard-item">
    <img src={imageUrl} alt={title} className="dashboard-icon"/>
    <div >
      <h2 className="dashboard-title">{title}</h2>
      <p className="dashboard-description">{description}</p>
      <Link to={buttonLink} className="dashboard-button">
        {buttonText}
      </Link>
    </div>
  </div>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
};

const Dashboard = ({ items }) => (
  <div className="dashboard">
    {items.map((item, index) => (
      <Card key={index} {...item} />
    ))}
  </div>
);

Dashboard.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      buttonLink: PropTypes.string.isRequired,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
};

export default Dashboard;