import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import { useTranslation } from 'react-i18next';
import Image from '../../Images/Produit/General.webp';
import TestBanner from '../../Components/TestBanner';
import Features from '../../Components/FeatureSection';
import slide1 from '../../Images/Produit/GeneralScreen.webp'; 
import slide2 from '../../Images/Produit/GeneralScreen2.webp'; 
import Accordion from '../../Components/Accordion';
import TextBanner from '../../Components/TextBanner';
import HeroBanner from '../../Components/HeroBanner';
import Carousel from '../../Components/Carousel';
import BackImg from '../../Images/Accueil/Background.webp';
import '../../Style/Produit.css';
import { Helmet } from 'react-helmet';

const Admin = () => {
  const { t } = useTranslation('general');

  const featureData = [
    {
      title: t("admin.feature1Title"),
      content: t("admin.feature1Content"),
      link: "/Solution/Gérez-vos-finances",
    },
    {
      title: t("admin.feature2Title"),
      content: t("admin.feature2Content"),
      link: "/Solution/Gérez-votre-temps",
    },
    {
      title: t("admin.feature3Title"),
      content: t("admin.feature3Content"),
      link: "/Solution/Gérez-votre-vie-administrive",
    },
    {
      title: t("admin.feature4Title"),
      content: t("admin.feature4Content"),
      link: "/Solution/Gérez-votre-immobilier-et-vos-véhicules",
    },
    {
      title: t("admin.feature5Title"),
      content: t("admin.feature5Content"),
      link: "/Solution/Gérez-vos-relations",
    },
    {
      title: t("admin.feature6Title"),
      content: t("admin.feature6Content"),
      link: "/Solution/Gérez-votre-carrière-pro",
    },
    {
      title: t("admin.feature7Title"),
      content: t("admin.feature7Content"),
      link: "/Solution/Gérez-votre-empreinte-carbone",
    },
    {
      title: t("admin.feature8Title"),
      content: t("admin.feature8Content"),
      link: "/Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
    },
  ];

  const slides = [
    { src: slide1, type: 'img' },
  ];

  const slides2 = [
    { src: slide2, type: 'img' },
  ];



    return (
      <>
        <Helmet>
            <title>{t("admin.pageTitle")}</title>
            <meta name="title" content={t("admin.pageMetaTitle")}/>
            <meta name="description" content={t("admin.pageMetaDescription")} />
            <link rel="canonical" href="https://be-lifer.com/Solution/Présentation-solution"/>
        </Helmet>
        <div>
          <Encart 
            title={t("admin.personalManagementTitle")} 
            content={t("admin.personalManagementContent")} 
            image={Image}
          />
          <TextBanner
            title={t("admin.whatIsLifeTitle")}
            content={t("admin.whatIsLifeContent")}
            level="h2"
          />
          <Carousel slides={slides} />
          <TextBanner
            title={t("admin.ourDifferenceTitle")}
            content={t("admin.ourDifferenceContent")}
            level="h3"
          />
          <Carousel slides={slides2} />
          <TestBanner />
          <h4>{t("admin.solutionsForYouTitle")}</h4>
          <Features features={featureData} />
          <TestBanner />
          <HeroBanner
            title={t("admin.unifyYourLifeTitle")}
            subtitle={t("admin.unifyYourLifeSubtitle")}
            buttonText={t("admin.comingSoonButton")}
            buttonLink="https://be-lifer.com/rejoignez-nous/Devenez-Testeur"
            backgroundImage={BackImg}
          />
        </div>
      </>
    );
};

export default Admin;