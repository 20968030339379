import React from 'react';
import '../Style/Produit.css'; 

const Encart = ({ title, content, image }) => {
    return (
      <div className="encart-container">
        <img src={image} alt={title} className="card-image" />
        <div className="encart-text">
          <h1>{title}</h1>
          <div className="title-underline"></div> 
          <p>{content}</p>
        </div>
      </div>
    );
};

export default Encart;