import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/About/Job.webp';
import TextBanner from '../../Components/TextBanner';
import ContactForm from '../../Components/ContactForm';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Job = () => {
  const { t } = useTranslation('about');
  const sendEmail = async (formData) => {
    try {
      // const response = await fetch('http://localhost:3002/send-email', {
      const response = await fetch('https://osqvaiaq1d.execute-api.us-east-1.amazonaws.com/prod/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Echec de l\'envoi de l\'email');
      }
  
      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email:', error);
    }
  };

  const RelationOptions = [
    { value: "Collaborateur", text: t('job.relationOptions.0.text') },
    { value: "Collaborateur", text: t('job.relationOptions.1.text') },
    { value: "Collaborateur", text: t('job.relationOptions.2.text') },
    { value: "Collaborateur", text: t('job.relationOptions.3.text') },
    { value: "Collaborateur", text: t('job.relationOptions.4.text') },
    { value: "Collaborateur", text: t('job.relationOptions.5.text') },
    { value: "Collaborateur", text: t('job.relationOptions.6.text') },
    { value: "Collaborateur", text: t('job.relationOptions.7.text') },
    { value: "Collaborateur", text: t('job.relationOptions.8.text') }
  ];
  
  return (
    <>
      <Helmet>
        <title>{t('job.pageTitle')}</title>
        <meta name="title" content={t('job.pageMetaTitle')} />
        <meta name="description" content={t('job.pageMetaDescription')} />
        <link rel="canonical" href="https://be-lifer.com/rejoignez-nous/devenez-collaborateur" />
      </Helmet>
      <div>
        <Encart 
          title={t('job.encartTitle')}
          content={t('job.encartContent')}
          image={Image}
        />
        <TextBanner
          title={t('job.textBannerTitle1')}
          content={t('job.textBannerContent1')}
          level="h2"
        />
        <TextBanner
          title={t('job.textBannerTitle2')}
          content={t('job.textBannerContent2')}
          level="h3"
        />
        <ContactForm 
          defaultMessagePlaceholder={t('job.contactFormPlaceholder')}
          defaultRelation={t('job.contactFormDefaultRelation')}
          options={RelationOptions}
          sendEmail={sendEmail}
        />
      </div>
    </>
  );
};

export default Job;