import React from 'react';
import '../Style/Banner.css';
import { Link } from 'react-router-dom';

// Composant pour chaque section individuelle
const FeatureSection = ({ title, content, link }) => (
  <div className="feature">
    <div className='h7'>{title}</div>
    <p>{content}</p>
    <Link to={link}>En savoir plus </Link>
  </div>
);

// Composant principal qui utilise FeatureSection pour chaque feature
const Features = ({ features }) => (
  <div className="features-container">
    {features.map((feature, index) => (
      <FeatureSection key={index} {...feature} />
    ))}
  </div>
);

export default Features;
