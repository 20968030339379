// RoadmapCont.jsx
import React, { useState, useMemo } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import { Collapse } from 'react-collapse';
import '../Style/RoadmapCont.css'; // Assure-toi que le chemin est correct

// Ce composant affiche une liste déroulante pour le filtre
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calcule les options pour le filtre déroulant en rassemblant toutes les valeurs uniques dans la pré-filtration des lignes
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      value={filterValue}
    >
      <option value="">Tous</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

const TableComponent = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy
  );

  // Le code pour le rendu du tableau ici...
  return (
    // <div className="tableContainer" >
      <table {...getTableProps()} className="table">
        <thead className="tableHeader">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="ableHeaderCell">
                  {column.render('Header')}
                  {/* Ajout d'un champ de saisie pour le filtre */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="tableBody">
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="tableRow">
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} className="tableCell">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    // </div>
  );
};

const FAQComponent = ({ faqs }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleCollapse = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  

  // useMemo pour les colonnes déplacé ici, à l'intérieur du composant FAQComponent
  const columns = useMemo(() => [
    {
      Header: 'Domaine de vie',
      accessor: 'domaine',
      Filter: SelectColumnFilter,
      filter: 'includes',
    },
    {
      Header: 'Solution',
      accessor: 'solution',
      Filter: SelectColumnFilter,
      filter: 'includes',
    },
    {
      Header: 'Titre',
      accessor: 'titre',
      Filter: SelectColumnFilter,
      filter: 'includes',
    },
    {
      Header: 'Description',
      accessor: 'description',
      Filter: SelectColumnFilter,
      filter: 'includes',
    },
    {
      Header: 'Catégorie',
      accessor: 'categorie',
      Filter: SelectColumnFilter,
      filter: 'includes',
    },
    // Plus de colonnes selon les propriétés de tes données
  ], []);

  return (
    <div>
      {faqs.map((faq, index) => (
        <div key={index} className="faqItem">
          <div className="buttonToggle" onClick={() => toggleCollapse(index)}>
            {faq.question}
            <span>{openIndex === index ? '−' : '+'}</span>
          </div>
          {openIndex === index && (
            <div className="collapseContainer">
              <img src={faq.image} alt="Image descriptive" className="faqImage"/>
              <p className="introText">{faq.introText}</p>
              <TableComponent columns={columns} data={faq.data} />
              <p className="introText">{faq.introText2}</p>
              <img src={faq.image2} alt="Image descriptive" className="faqImage"/>
          </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const RoadmapCont = ({ faqs }) => {
  return <FAQComponent faqs={faqs} />;
};

export default RoadmapCont;
