import React from 'react';

const TermsOfUse = () => {
    const siteName = "Life";
    const currentDate = new Date().toLocaleDateString();

    return (
        <div className="terms-of-use">
          <h2>Conditions d'Utilisation de {siteName}</h2>
          <p>Date de la dernière mise à jour : {currentDate}</p>
    
          <p>Bienvenue sur {siteName} ! En accédant à notre site web, vous acceptez d'être lié par les présentes conditions d'utilisation, toutes les lois et réglementations applicables, et convenez que vous êtes responsable de la conformité avec toutes les lois locales applicables. Si vous n'êtes pas d'accord avec l'une de ces conditions, vous êtes interdit d'utiliser ou d'accéder à ce site. Les matériaux contenus dans ce site sont protégés par les lois applicables sur le droit d'auteur et les marques.</p>
    
          {/* ... autres sections ... */}
    
          <h3>1. Licence d'Utilisation</h3>
          <p>Il vous est temporairement permis de télécharger une copie des matériaux (informations ou logiciels) sur {siteName} pour un affichage personnel et non commercial transitoire seulement. C'est l'octroi d'une licence, pas un transfert de titre, et sous cette licence, vous ne pouvez pas :
                modifier ou copier les matériaux ;
                utiliser les matériaux à des fins commerciales ou pour toute exposition publique (commerciale ou non commerciale) ;
                tenter de décompiler ou de désosser tout logiciel contenu sur le site web de {siteName} ;
                supprimer tout droit d'auteur ou autres notations de propriété des matériaux ;
                transférer les matériaux à une autre personne ou "miroir" les matériaux sur un autre serveur.
                Cette licence sera automatiquement résiliée si vous violez l'une de ces restrictions et peut être résiliée par {siteName} à tout moment. Lors de la fin de votre visualisation de ces matériaux ou à la fin de cette licence, vous devez détruire tous les matériaux téléchargés en votre possession, que ce soit en format électronique ou imprimé.</p>
                    
          <h3>2. Avertissement</h3>
          <p>Les matériaux sur le site web de {siteName} sont fournis "en l'état". {siteName} ne donne aucune garantie, expresse ou implicite, et décline par la présente et annule toutes les autres garanties, y compris, sans limitation, les garanties implicites ou les conditions de qualité marchande, d'adéquation à un usage particulier, ou de non-violation des droits de propriété intellectuelle ou d'autres violations des droits.
            De plus, {siteName} ne garantit ni ne fait aucune représentation concernant l'exactitude, les résultats probables, ou la fiabilité de l'utilisation des matériaux sur son site Internet ou autrement liés à de tels matériaux ou sur tout site lié à ce site.</p>
    
          <h3>3. Limitations</h3>
          <p>En aucun cas, {siteName} ou ses fournisseurs ne seront responsables de tout dommage (y compris, sans limitation, les dommages pour perte de données ou profit, ou en raison d'une interruption d'activité) découlant de l'utilisation ou de l'incapacité à utiliser les matériaux sur le site Internet de {siteName}, même si {siteName} ou un représentant autorisé de {siteName} a été informé oralement ou par écrit de la possibilité de tels dommages. Parce que certaines juridictions ne permettent pas les limitations sur les garanties implicites, ou les limitations de responsabilité pour les dommages consécutifs ou accessoires, ces limitations peuvent ne pas s'appliquer à vous.</p>

          <h3>Précision des Matériaux</h3>
          <p>Les matériaux apparaissant sur le site web de {siteName} pourraient inclure des erreurs techniques, typographiques ou photographiques. {siteName} ne garantit pas que les matériaux sur son site web sont exacts, complets ou à jour. {siteName} peut apporter des modifications aux matériaux contenus sur son site web à tout moment sans préavis. Cependant, {siteName} ne s'engage pas à mettre à jour les matériaux.
        </p>

          <h3>5. Liens</h3>
          <p>{siteName} n'a pas examiné tous les sites liés à son site Internet et n'est pas responsable du contenu de ces sites liés. L'inclusion de tout lien n'implique pas l'approbation par {siteName} du site. L'utilisation de tout tel site web lié est aux risques et périls de l'utilisateur.</p>

          <h3>6. Modifications des Conditions d'Utilisation</h3>
          <p>{siteName} peut réviser ces conditions d'utilisation pour son site web à tout moment sans préavis. En utilisant ce site web, vous acceptez d'être lié par la version actuelle de ces conditions d'utilisation.</p>

          <h3>7. Loi Applicable</h3>
          <p>Toute réclamation relative au site web de {siteName} sera régie par les lois de l'État/pays de {siteName} sans égard à son conflit de dispositions légales.</p>
        </div>
      );
    }
    
    export default TermsOfUse;