import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import financeImage from '../../Images/Produit/Finance.webp';
import BackFinance from '../../Images/Produit/FinanceBack.webp';
import slide1 from '../../Images/Produit/FinanceScreen.webp'; 
import slide2 from '../../Images/Produit/Phone Finance.mp4'; 
import Accordion from '../../Components/Accordion';
import TextBanner from '../../Components/TextBanner';
import Features from '../../Components/FeatureSection';
import MonCarrousel from '../../Components/MonCarrousel';
import HeroBanner from '../../Components/HeroBanner';
import ModulesFeat from '../../Components/ModulesFeat';
import TestBanner from '../../Components/TestBanner';
import Carousel from '../../Components/Carousel';
import ImageTime from '../../Images/Produit/Time.webp';
import ImageDomicile from '../../Images/Produit/Domicile.webp';
import ImageAdmin from '../../Images/Produit/Admin.webp';
import ImageEco from '../../Images/Produit/Eco.webp';
import ImagePerso from '../../Images/Produit/Perso.webp';
import ImagePro from '../../Images/Produit/Pro.webp';
import ImageSocial from '../../Images/Produit/Social.webp';
import ImageGeneral from '../../Images/Produit/General.webp';
import Advantages from '../../Components/Advantage';
import Vision from '../../Images/Produit/Vision.webp';
import Efficacite from '../../Images/Produit/Efficacite.webp';
import Precision from '../../Images/Produit/Precision.webp';
import { Helmet } from 'react-helmet';


const Finance = () => {
  const advantagesList = [
    {
      icon: Efficacite,
      title: 'Efficacité',
      description: "Automatisez l'importation de vos données financières pour une gestion simplifiée et moins chronophage.",
    },
    {
      icon: Precision,
      title: 'Précision',
      description: "Obtenez une vue d'ensemble rapide de vos transactions et résumés mensuels avec un tableau de bord personnalisable.",
    },
    {
      icon: Vision,
      title: 'Vision',
      description: "Planifiez votre avenir financier et gérez vos investissements en alignant vos actions actuelles sur vos objectifs à long terme.",
    },
  ];

  const pageData = {
    title: 'NOS AUTRES SOLUTIONS',
    items: [
      {
        imageUrl: ImageSocial,
        title: 'Les relations sociales',
        link: '/Solution/Gérez-vos-relations'
      },
      {
        imageUrl: ImageTime,
        title: 'Le temps',
        link: '/Solution/Gérez-votre-temps'
      },
      {
        imageUrl: ImageAdmin,
        title: "L'administrative",
        link: '/Solution/Gérez-votre-vie-administrive'
      },
      {
        imageUrl: ImagePro,
        title: 'La carrière pro',
        link: '/Solution/Gérez-votre-carrière-pro'
      },
      {
        imageUrl: ImagePerso,
        title: 'La vie perso',
        link: '/Solution/Gérez-votre-santé-sport-alimentation-spiritualité'
      },
      {
        imageUrl: ImageEco,
        title: 'Notre envirronnement',
        link: '/Solution/Gérez-votre-empreinte-carbone'
      },
      {
        imageUrl: ImageDomicile,
        title: 'Le domicile',
        link: '/Solution/Gérez-votre-immobilier-et-vos-véhicules'
      },
      {
        imageUrl: ImageGeneral,
        title: "L'intégration",
        link: '/Solution'
      },
    ]
  };

  const featureData = [
    {
      title: "Maximisation et Précision des Opérations Financières",
      content: "Automatisez l'importation de vos données financières dans votre compte en banque pour une gestion moins chronophage et plus rentable. Cette précision vous libère du temps pour se concentrer sur des décisions stratégiques plus importantes, comme comment investir dans les crypto-monnaies ou comment maximiser les loyers de vos propriétés.",
      link: "/Solution/Gérez-vos-finances",
    },
    {
      title: "Planification à Long Terme : Vision et Sacrifice",
      content: "Planifiez judicieusement votre avenir financier en alignant vos actions présentes avec vos aspirations futures. Adopter une approche de sacrifice aujourd'hui pour une vie de luxe demain est essentiel pour échapper à la 'rat race'. Faites de vos investissements en bitcoin, en immobilier ou même dans les paris sportifs, des piliers de votre stratégie de croissance financière.",
      link: "/Solution/Gérez-vos-finances",
    },
    {
      title: "Solutions Financières Personnalisées : Une Route Vers la Richesse",
      content: "Notre plateforme assure une gestion transparente et sécurisée de vos finances, idéale pour ceux qui aspirent à faire de l'argent de manière consistante. L'intégration de vos données bancaires et de vos comptes épargne se fait en temps réel, vous permettant de prendre des décisions éclairées basées sur des informations à jour.",
      link: "/Solution/Gérez-vos-finances",
    },
    {
      title: "Définir des Objectifs Clairs et Visualiser Votre Progrès",
      content: "Fixez des objectifs financiers précis tels que 'Économiser pour des vacances de luxe', 'Créer un fonds d'urgence substantiel' ou '% par classification de dépense'. Notre application facilite le suivi de vos progrès et l'ajustement de vos stratégies pour maximiser vos revenus passifs, vos investissements locatifs et même vos gains potentiels de la loterie ou du loto.",
      link: "/Solution/Gérez-vos-finances",
    },
    {
      title: "Optimisation de Vos Investissements : Bitcoin et Marché Boursier",
      content: "Optimisez vos compétences en tant qu'investisseur grâce à notre plateforme qui offre des analyses détaillées de votre portefeuille, que ce soit en actions boursières ou en crypto-monnaies. Même Napoléon aurait bénéficié de tels outils pour gérer et accroître son empire financier!",
      link: "/Solution/Gérez-vos-finances",
    },
    {
      title: "Cultiver Votre Bien-Être Financier pour Être Heureux",
      content: "Notre application va au-delà de la simple gestion d'argent; elle vous guide sur comment devenir riche, comment investir intelligemment, et comment utiliser des outils comme le compte épargne, les loyers et le cash flow pour sécuriser votre futur. Profitez de la tranquillité d'esprit qui vient avec une gestion financière supérieure.",
      link: "/Solution/Gérez-vos-finances",
    },
    {
      title: "Un Avenir Financier Rayonnant",
      content: "En adoptant ces stratégies, vous transformez non seulement votre portefeuille mais aussi votre vie, vous menant vers une liberté financière et un bonheur durable. La richesse ne se limite pas à l'argent, mais à une vie remplie de satisfaction et de réalisations, et chaque section de notre approche vous engage à agir pour réaliser ces rêves.",
      link: "/Solution/Gérez-vos-finances",
    },
  ];

  const slides = [
    { src: slide1, type: 'img' },
  ];

  const slides2 = [
    { src: slide2, type: 'video' },
  ];

  const faq = [
    {
      question: "Qu'est-ce que le module finance et comment peut-il m'aider ?",
      answer: "Le module finance est conçu pour vous aider à gérer vos finances personnelles de manière intuitive et efficace. Il vous permet de suivre vos dépenses, de planifier votre budget, et d'analyser vos habitudes financières pour une meilleure prise de décision."
    },
    {
      question: "Comment sécuriser mes informations financières dans l'application ?",
      answer: "Nous utilisons des protocoles de sécurité avancés, comme le chiffrement SSL/TLS, pour protéger vos données. Vos informations financières sont stockées de manière sécurisée et ne sont accessibles qu'avec votre autorisation explicite."
    },
    {
      question: "Puis-je connecter mes comptes bancaires au module finance ??",
      answer: "Oui, notre application permet de connecter vos comptes bancaires pour un suivi automatique des transactions. Nous supportons une large gamme de banques, vous permettant d'avoir une vue complète de vos finances en un seul endroit."
    },
    {
      question: "Comment le module finance gère-t-il les différentes devises ?",
      answer: "Le module prend en charge plusieurs devises et met à jour automatiquement les taux de change. Cela vous permet de gérer vos transactions internationales facilement et de manière précise."
    },
    {
      question: "Peut-on créer et suivre un budget avec le module finance ?",
      answer: "Absolument. Vous pouvez créer des budgets personnalisés, définir des limites de dépenses pour différentes catégories, et suivre vos dépenses par rapport à ces budgets pour une meilleure gestion financière."
    },
    {
      question: "Comment le module aide-t-il à optimiser les économies et les investissements ?",
      answer: "Le module offre des analyses détaillées de vos dépenses et recommande des moyens d'optimiser vos économies. Il peut également suggérer des stratégies d'investissement adaptées à votre profil financier."
    },
    {
      question: "Est-il possible d'obtenir des rapports et des analyses financières personnalisées ?",
      answer: "Oui, vous pouvez générer des rapports personnalisés qui vous aident à comprendre vos habitudes de dépenses, vos revenus, et à planifier vos objectifs financiers à court et à long terme."
    },
    {
      question: "Le module finance propose-t-il des alertes ou des notifications pour la gestion des finances ?",
      answer: "Oui, vous pouvez configurer des alertes personnalisées pour des transactions spécifiques, des rappels de paiement de factures, et recevoir des notifications pour des événements financiers importants."
    },
    {
      question: "Puis-je accéder au module finance depuis différents appareils ?",
      answer: "Oui, notre application est conçue pour fonctionner sur plusieurs appareils. Vos données sont synchronisées en temps réel, vous permettant d'accéder à vos informations financières où que vous soyez."
    },
    {
      question: "Qui contacter en cas de problèmes ou de questions supplémentaires ?",
      answer: "Pour toute assistance technique ou questions supplémentaires, vous pouvez nous contacter via notre support client, disponible par e-mail, chat en direct, ou téléphone."
    },
  ];
  
  return (
    <>
      <Helmet>
          <title>Be-lifer, Devenir Maître de Vos Finances Personnelles</title>
          <meta name="description" content="Be-lifer, Devenir Maître de Vos Finances Personnelles" />
          <link rel="canonical" href="https://be-lifer.com/Solution/Gérez-vos-finances"/>
          <meta name="author" content="Be-lifer"/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content="Be-Lifer : Devenir Maître de Vos Finances Personnelles"/>
          <meta property="og:description" content="Découvrez Life, votre outil stratégique pour la gestion financière. Maximisez vos revenus passifs et simplifiez votre quotidien. Rejoignez-nous aujourd'hui pour transformer votre vie !"/>
          <meta property="og:url" content="https://www.be-lifer.com/Solution/Gérez-vos-finances"/>
          <meta property="og:site_name" content="Be-Lifer"/>
      </Helmet>
      <div>
        <Encart 
          title="Introduction à la Sagesse Financière : Cultiver l'Abondance" 
          content="La gestion des finances personnelles est l'art de l'équilibre et de la précision, une voie vers l'autonomie et la prospérité. Voyez-vous comme le mentor et le banquier de votre destin financier, où chaque choix d'investissement vous propulse vers le statut de millionnaire, voire de milliardaire. Chaque euro placé dans un livret A, un investissement locatif ou même une crypto-monnaie est un pas de plus vers une abondance durable." 
          image={financeImage}
        />
        <TextBanner
          title = "Stratégies Financières : Cash Flow et Abondance pour Devenir Millionnaire"
          content = "Faites de la gestion stratégique de vos finances le pilier central de vos ambitions, transformant radicalement votre niveau de vie et vous propulsant vers l'abondance. Que ce soit par la génération de revenus passifs, l'investissement locatif ou le trading boursier, chaque décision doit contribuer à un impact social positif et à votre développement personnel. Envisagez chaque euro investi comme un pas vers la réalisation de vos rêves. Concentrez-vous sur des investissements judicieux qui construisent un portefeuille diversifié, garantissant des loyers constants et un cash flow robuste pour échapper à la rat race et devenir rentier. Chaque étape de ce parcours est une opportunité d'augmenter votre richesse, de maximiser votre rentabilité et de vivre une vie riche en expériences et satisfaction, transformant ainsi vos aspirations financières en une réalité tangible et durable."
          level = "h2"
        />
        <Carousel slides={slides} />
        <Advantages advantagesList={advantagesList} level="h3"  /> 
        <TestBanner />
        <Carousel slides={slides2} />
        <TestBanner />
        <h4>Des solutions conçues pour vous</h4>
        <Features features={featureData} />
        <TestBanner />
        <h5>Des Questions</h5>
        <Accordion faq={faq} />
        <HeroBanner
          title="Améliorer votre bien-être financier"
          subtitle="Savourez la tranquillité"
          buttonText="Démarrer gratuitement"
          buttonLink="https://be-lifer.com/rejoignez-nous/Devenez-Testeur"
          backgroundImage={BackFinance}
        />
        
        <MonCarrousel title={pageData.title} items={pageData.items} visibleItemsCount={3}  />
      </div>
    </>
  );
};

export default Finance;
