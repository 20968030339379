import React from 'react';
import '../Style/Banner.css'; // Assure-toi d'avoir un fichier CSS pour styliser le composant

const ImgBanner = ({ title, content, image }) => {
  return (
    <div className="imgbanner-container">
      <h2 className="imgbanner-title">{title}</h2>
      <div className="imgbanner-container2">
        <p className="imgbanner-content">{content}</p>
        <img src={image} alt={title} className="imgcard-image" />
      </div>
    </div>
  );
};

export default ImgBanner;