import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/Produit/Admin.webp';
import TestBanner from '../../Components/TestBanner';
import BackAdmin from '../../Images/Produit/AdminBack.webp';
import Features from '../../Components/FeatureSection';
import slide1 from '../../Images/Produit/AdminScreen.webp'; 
import slide2 from '../../Images/Produit/AdminScreen2.webp'; 
import Accordion from '../../Components/Accordion';
import TextBanner from '../../Components/TextBanner';
import HeroBanner from '../../Components/HeroBanner';
import Carousel from '../../Components/Carousel';
import ImageTime from '../../Images/Produit/Time.webp';
import ImageDomicile from '../../Images/Produit/Domicile.webp';
import ImageFinance from '../../Images/Produit/Finance.webp';
import ImageAdmin from '../../Images/Produit/Admin.webp';
import ImageEco from '../../Images/Produit/Eco.webp';
import ImagePerso from '../../Images/Produit/Perso.webp';
import ImagePro from '../../Images/Produit/Pro.webp';
import ImageSocial from '../../Images/Produit/Social.webp';
import ImageGeneral from '../../Images/Produit/General.webp';
import MonCarrousel from '../../Components/MonCarrousel';
import Advantages from '../../Components/Advantage';
import Efficacite from '../../Images/Produit/Efficacite.webp';
import Securite from '../../Images/Produit/Securite.webp';
import Productivite from '../../Images/Produit/Productivite.webp';
import { Helmet } from 'react-helmet';
import '../../Style/Produit.css';

const Admin = () => {
  const advantagesList = [
    {
      icon: Efficacite,
      title: 'Efficacité',
      description: "Centralisez toutes vos données administratives, documents importants et informations gouvernementales en un seul lieu pour un accès simplifié",
    },
    {
      icon: Securite,
      title: 'Prévention',
      description: "Assurez la sécurité de vos données grâce à un chiffrement robuste et un stockage sécurisé.",
    },
    {
      icon: Productivite,
      title: 'Productivité',
      description: "Personnalisez vos rappels et marquez les événements clés pour rester organisé et à jour.",
    },
  ];

  const pageData = {
    title: 'NOS AUTRES SOLUTIONS',
    items: [
      {
        imageUrl: ImageSocial,
        title: 'Les relations sociales',
        link: '/Solution/Gérez-vos-relations'
      },
      {
        imageUrl: ImageTime,
        title: 'Le temps',
        link: '/Solution/Gérez-votre-temps'
      },
      {
        imageUrl: ImagePro,
        title: 'La carrière pro',
        link: '/Solution/Gérez-votre-carrière-pro'
      },
      {
        imageUrl: ImagePerso,
        title: 'La vie perso',
        link: '/Solution/Gérez-votre-santé-sport-alimentation-spiritualité'
      },
      {
        imageUrl: ImageEco,
        title: 'Notre envirronnement',
        link: '/Solution/Gérez-votre-empreinte-carbone'
      },
      {
        imageUrl: ImageDomicile,
        title: 'Le domicile',
        link: '/Solution/Gérez-votre-immobilier-et-vos-véhicules'
      },
      {
        imageUrl: ImageFinance,
        title: "Les finances",
        link: '/Solution/Gérez-vos-finances'
      },
      {
        imageUrl: ImageGeneral,
        title: "L'intégration",
        link: '/Solution'
      },
    ]
  };

  const featureData = [
    {
      title: "Scanner Virtuel",
      content: "Numérisez et transformez facilement vos documents papier en fichiers numériques sécurisés. Classez, taguez et rendez-les recherchables pour un accès rapide et organisé, simplifiant ainsi votre gestion documentaire.  ",
      link: "/Solution/Gérez-votre-vie-administrive",
    },
    {
      title: "Bibliothèque digitale",
      content: "Après numérisation, vos documents sont automatiquement rangés dans des dossiers pertinents selon leur type ou catégorie. Créez des sous-dossiers, ajustez les niveaux d'accès et partagez les documents facilement selon vos besoins.",
      link: "/Solution/Gérez-votre-vie-administrive",
    },
    {
      title: "Rappel Administratif",
      content: "L'application utilise calendriers nationaux, les données des documents numérisés, telles que les dates d'échéance de factures ou de renouvellement de contrats, pour générer des rappels automatisés, vous informant des actions à entreprendre.",
      link: "/Solution/Gérez-votre-vie-administrive",
    },
    {
      title: "Gestionnaire de dates clés",
      content: "À chaque numérisation d'un document impliquant une obligation financière ou un renouvellement, l'application suit ces échéances clés. Visualisez un calendrier des paiements, renouvellements de contrats et autres engagements importants à venir.",
      link: "/Solution/Gérez-votre-vie-administrive",
    },
  ];

  const slides = [
    { src: slide1, type: 'img' },
  ];

  const slides2 = [
    { src: slide2, type: 'img' },
  ];

    return (
      <div>
        <Helmet>
          <title>Be-lifer, Maîtrisez la Gestion Administrative avec Life</title>
          <meta name="title" content="Be-lifer, Maîtrisez la Gestion Administrative avec Life"/>
          <meta name="description" content="Simplifiez votre admin avec Life : gestion de documents et connexion aux services gouvernementaux. Tout en quelques clic." />
          <link rel="canonical" href="https://be-lifer.com/Solution/Gérez-votre-vie-administrive"/>
        </Helmet>
        <Encart 
          title="Impératif Administratif" 
          content="Piliers de l'intégrité et de la dévotion civique, nos structures administratives et notre engagement en tant que citoyens forment un édifice de gouvernance et de participation démocratique sans égal. Ensemble, ils incarnent l'excellence dans la préservation de l'ordre social et la promotion du bien commun, reflétant l'authentique esprit de responsabilité qui anime la vie citoyenne." 
          image={Image}
        />
        <TestBanner />
        <TextBanner
          title = "Simplifier les tâches administratives quotidiennes"
          content = "Transformez et simplifiez votre gestion administrative avec notre système intégré, qui centralise non seulement vos documents, mais relie également vos tâches administratives aux services gouvernementaux. Du rappel des échéances à l'automatisation des processus, notre plateforme couvre tout, de la numérisation des documents jusqu'à la création de notifications pour les renouvellements, les paiements, et même pour des événements gouvernementaux importants comme les périodes de vote. Accédez facilement à une gestion complète et connectée, rendant vos interactions avec les services publics plus fluides et intuitives."
        />
        
        <Carousel slides={slides} />
        <Advantages advantagesList={advantagesList} /> 
        <TestBanner />
        <Carousel slides={slides2} />
        <TestBanner />
        <h1>Des solutions conçues pour vous</h1>
        <Features features={featureData} />
        <TestBanner />
        <HeroBanner
          title="Simplifiez votre vie administratif"
          subtitle="Libérez votre temps"
          buttonText="Démarrer gratuitement"
          buttonLink="https://be-lifer.com/rejoignez-nous/Devenez-Testeur"
          backgroundImage={BackAdmin}
        />
        
        <MonCarrousel title={pageData.title} items={pageData.items} visibleItemsCount={3} />
      </div>
    );
};

export default Admin;