import React from 'react';
import PropTypes from 'prop-types';
import '../Style/Accueil.css';  
import { Link } from 'react-router-dom';

const HeroBanner = ({ title, subtitle, buttonText, buttonLink, backgroundImage }) => {
  return (
    <div className="hero-banner" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="hero-content1">
        <div className='h7'>{title}</div>
        <p>{subtitle}</p>
        <Link to={buttonLink} className="hero-button">{buttonText}</Link>
      </div>
    </div>
  );
};

HeroBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
};

export default HeroBanner;