import React, { useState, useEffect  } from 'react';
import { useTranslation } from 'react-i18next';
import '../Style/ModulesFeat.css';
import { Link } from 'react-router-dom';

function ModulesFeat({ data }) {
  const { i18n } = useTranslation();
  const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(data)[0]);
  const [activeSection, setActiveSection] = useState(data[activeSectionKey] || {});

  // Met à jour l'état actif à chaque changement de langue
  useEffect(() => {
    setActiveSectionKey(Object.keys(data)[0]);
    setActiveSection(data[Object.keys(data)[0]] || {});
  }, [i18n.language, data]); // Dépendance sur la langue et les données


  return (
    <div className="financial-module">
      <div className="sidebar">
        {Object.keys(data).map((sectionKey) => (
          <button
            key={sectionKey}
            className={`sidebar-item ${activeSectionKey === sectionKey ? 'active' : ''}`}
            onClick={() => {
              setActiveSectionKey(sectionKey);
              setActiveSection(data[sectionKey] || {}); // Assure que l'état est bien mis à jour
            }}
          >
            <img 
              src={activeSectionKey === sectionKey 
                ? data[sectionKey]?.iconActivePath || '' 
                : data[sectionKey]?.iconInactivePath || ''} 
              alt={`${sectionKey} icon`} 
              className="sidebarimg"
            />
            {sectionKey}
          </button>
        ))}
      </div>
      <div className="content">
        {activeSection.iconPath ? (
            <img src={activeSection.iconPath} alt={`${activeSection.title} icon`} className="ContentIcon" />
          ) : (
            <p>Icon non disponible</p> // Message d'erreur plus explicite
          )}
        <h4>{activeSection.title || 'Titre non disponible'}</h4>
        <p>{activeSection.description || 'Description non disponible'}</p>
        <div className="SubContent">
            <ul>
                {activeSection.bullets && activeSection.bullets.map((bullet, index) => (
                <li key={index}>{bullet}</li>
                ))}
            </ul>
            <div className="images-container">
              {activeSection.images ? (
                activeSection.images.map((image, index) => (
                  <img key={index} src={image} alt={`Content related visual ${index + 1}`} />
                ))
              ) : (
                <p>Aucune image disponible</p>
              )}
            </div>  
        </div>
        {activeSection.buttonText && (
            <Link to={activeSection.buttonLink} className="section-button"> {activeSection.buttonText} </Link>
        )}
      </div>
    </div>
  );
}

export default ModulesFeat;
