import React, { useState } from 'react';
import TextBanner from '../../Components/TextBanner';
import YouTubeVideo from '../../Components/Youtubevideo';
import HeroBanner from '../../Components/HeroBanner';
import BackImg from '../../Images/Accueil/Background.webp';
import { Helmet } from 'react-helmet';
import Encart from '../../Components/Encart';
import Image from '../../Images/Produit/parent.webp';
import { useTranslation } from 'react-i18next';

import '../../Style/Produit.css';

const Parent = () => {
    const { t } = useTranslation('solution');
    return (
        <>
            <Helmet>
                <title>{t('parent.pageTitle')}</title>
                <meta name="title" content={t('parent.pageMetaTitle')} />
                <meta name="description" content={t('parent.pageMetaDescription')} />
                <link rel="canonical" href="https://be-lifer.com/Solution/Nos-solutions-pour-les-familles" />
            </Helmet>
            <div>
                <Encart 
                    title={t('parent.encartTitle')} 
                    content={t('parent.encartContent')} 
                    image={Image}
                />
                <TextBanner
                    title={t('parent.textBannerTitle1')}
                    content={t('parent.textBannerContent1')}
                    level="h2" 
                />
                <TextBanner
                    title={t('parent.textBannerTitle2')}
                    content={t('parent.textBannerContent2')}
                    level="h3" 
                />
                <TextBanner
                    title={t('parent.textBannerTitle3')}
                    content={t('parent.textBannerContent3')}
                    level="h4"
                />
                <TextBanner
                    title={t('parent.textBannerTitle4')}
                    content={t('parent.textBannerContent4')}
                    level="h5"
                />
                <TextBanner
                    title={t('parent.textBannerTitle5')}
                    content={t('parent.textBannerContent5')}
                    level="h6"
                />
                <TextBanner
                    title={t('parent.textBannerTitle6')}
                    content={t('parent.textBannerContent6')}
                    level="h6"
                    className="h7"
                />
                <TextBanner
                    title={t('parent.textBannerTitle7')}
                    content={t('parent.textBannerContent7')}
                    level="h6"
                    className="h8"
                />
                <TextBanner
                    title={t('parent.textBannerTitle8')}
                    content={t('parent.textBannerContent8')}
                    level="h6"
                    className="h9"
                />
                <TextBanner
                    title={t('parent.textBannerTitle9')}
                    content={t('parent.textBannerContent9')}
                    level="h6"
                    className="h10"              
                />
                <HeroBanner
                    title={t('parent.heroBannerTitle')}
                    subtitle={t('parent.heroBannerSubtitle')}
                    buttonText={t('parent.heroBannerButtonText')}
                    buttonLink={t('parent.heroBannerButtonLink')}
                    backgroundImage={BackImg}
                />
            </div>
        </>
    );
};

export default Parent;