import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Carousel from '../Components/Carousel';
import slide1 from '../Images/Accueil/slide1.mp4'; 
import slide2 from '../Images/Accueil/slide2.mp4';
import slide3 from '../Images/Accueil/slide3.mp4';
// import slide1DE from '/images/en/Life Wheels DE.mp4';
// import slide2DE from '../Images/Accueil/slide2.mp4';
// import slide3DE from '../Images/Accueil/slide3.mp4';
// import slide1EN from '/images/en/Life Wheels EN.mp4'; 
// import slide2EN from '../Images/Accueil/slide2.mp4';
// import slide3EN from '../Images/Accueil/slide3.mp4';
// import slide1IT from '/images/en/Life Wheels IT.mp4'; 
// import slide2IT from '../Images/Accueil/slide2.mp4';
// import slide3IT from '../Images/Accueil/slide3.mp4';
import LifeBreak from '../Images/Accueil/LifeBreak.webp';
import AdminImg from '../Images/Produit/AdminScreen.webp'; 
import DomicileImg from '../Images/Produit/DomicileScreen.webp'; 
import TimeIconB from '../Images/Accueil/TimeIconB.webp'; 
import TimeIconG from '../Images/Accueil/TimeIconG.webp'; 
import PersoIconB from '../Images/Accueil/PersoIconB.webp';  
import PersoIconG from '../Images/Accueil/PersoIconG.webp'; 
import AdminIconB from '../Images/Accueil/AdminIconB.webp'; 
import AdminIconG from '../Images/Accueil/AdminIconG.webp'; 
import DomIconB from '../Images/Accueil/DomIconB.webp'; 
import DomIconG from '../Images/Accueil/DomIconG.webp'; 
import EcoIconB from '../Images/Accueil/EcoIconB.webp'; 
import EcoIconG from '../Images/Accueil/EcoIconG.webp'; 
import FiIconB from '../Images/Accueil/FiIconB.webp'; 
import FiIconG from '../Images/Accueil/FiIconG.webp'; 
import GeIconB from '../Images/Accueil/GeIconB.webp'; 
import GeIconG from '../Images/Accueil/GeIconG.webp'; 
import ProIconB from '../Images/Accueil/ProIconB.webp'; 
import ProIconG from '../Images/Accueil/ProIconG.webp'; 
import SoIconB from '../Images/Accueil/SoIconB.webp'; 
import SoIconG from '../Images/Accueil/SoIconG.webp'; 
import PersoImg from '../Images/Produit/PersoScreen.webp'; 
import ProImg from '../Images/Produit/ProScreen.webp'; 
import SocialImg from '../Images/Produit/SocialScreen.webp'; 
import EcoImg from '../Images/Produit/EcoScreen2.webp'; 
import PersonnalisationImg from '../Images/Accueil/Custom.webp';
import FiabiliteImg from '../Images/Accueil/Fiabilité.webp';
import GeneralImg from '../Images/Produit/GeneralScreen.webp'; 
import EvoImg from '../Images/Accueil/Evo.webp';
import AccessImg from '../Images/Accueil/Access.webp';
import InteImg from '../Images/Accueil/Inte.webp';
import BackImg from '../Images/Accueil/Background.webp';
import Release1Img from '../Images/Accueil/release1.webp';
import LeveImg from '../Images/Accueil/levé.webp';
import PartnerImg from '../Images/Accueil/Partner.webp';
import TimeImg from '../Images/Produit/TimeScreen2.webp'; 
import TestBanner from '../Components/TestBanner';
import ContactBanner from '../Components/ContactBanner';
import Advantages from '../Components/Advantage';
import UsBanner from '../Components/Usbanner';
import Accordion from '../Components/Accordion';
import HeroBanner from '../Components/HeroBanner';
import ModulesFeat from '../Components/ModulesFeat';
import placeholderImage1 from '../Images/Produit/FinanceScreen.webp';
import TextBanner from '../Components/TextBanner';
import RoadMapComponent from '../Components/RoadmapComponent';
import Encart from '../Components/Encart';
import GestionImage from '../Images/Accueil/GestionImage.webp'
import '../Style/Accueil.css';

function Accueil() {
  const { t, i18n } = useTranslation('accueil');
  const currentLanguage = i18n.language; 


  const slides = t('videos.slides', { returnObjects: true });

  const Data = {
    [t('titles.integration')]: {
      iconInactivePath: GeIconB,
      iconActivePath: GeIconG,
      iconPath: GeIconG,
      title: t('Data.integration.subtitle'),
      description: t('Data.integration.description'),
      bullets: t('Data.integration.bullets', { returnObjects: true }),
      images: [GeneralImg],
      buttonText: t('Data.integration.buttonText'),
      buttonLink: t('Data.integration.buttonLink'),
    },
    [t('titles.financialFreedom')]: {
      iconInactivePath: FiIconB,
      iconActivePath: FiIconG,
      iconPath: FiIconG,
      title: t('Data.financialFreedom.subtitle'),
      description: t('Data.financialFreedom.description'),
      bullets: t('Data.financialFreedom.bullets', { returnObjects: true }),
      images: [placeholderImage1],
      buttonText: t('Data.financialFreedom.buttonText'),
      buttonLink: t('Data.financialFreedom.buttonLink'),
    },
    [t('titles.timeManagement')]: {
      iconInactivePath: TimeIconB,
      iconActivePath: TimeIconG,
      iconPath: TimeIconG,
      title: t('Data.timeManagement.subtitle'),
      description: t('Data.timeManagement.description'),
      bullets: t('Data.timeManagement.bullets', { returnObjects: true }),
      images: [TimeImg],
      buttonText: t('Data.timeManagement.buttonText'),
      buttonLink: t('Data.timeManagement.buttonLink'),
    },
    [t('titles.administrativeSimplicity')]: {
      iconInactivePath: AdminIconB,
      iconActivePath: AdminIconG,
      iconPath: AdminIconG,
      title: t('Data.administrativeSimplicity.subtitle'),
      description: t('Data.administrativeSimplicity.description'),
      bullets: t('Data.administrativeSimplicity.bullets', { returnObjects: true }),
      images: [AdminImg],
      buttonText: t('Data.administrativeSimplicity.buttonText'),
      buttonLink: t('Data.administrativeSimplicity.buttonLink'),
    },
    [t('titles.homeHarmony')]: {
      iconInactivePath: DomIconB,
      iconActivePath: DomIconG,
      iconPath: DomIconG,
      title: t('Data.homeHarmony.subtitle'),
      description: t('Data.homeHarmony.description'),
      bullets: t('Data.homeHarmony.bullets', { returnObjects: true }),
      images: [DomicileImg],
      buttonText: t('Data.homeHarmony.buttonText'),
      buttonLink: t('Data.homeHarmony.buttonLink'),
    },
    [t('titles.professionalGrowth')]: {
      iconInactivePath: ProIconB,
      iconActivePath: ProIconG,
      iconPath: ProIconG,
      title: t('Data.professionalGrowth.subtitle'),
      description: t('Data.professionalGrowth.description'),
      bullets: t('Data.professionalGrowth.bullets', { returnObjects: true }),
      images: [ProImg],
      buttonText: t('Data.professionalGrowth.buttonText'),
      buttonLink: t('Data.professionalGrowth.buttonLink'),
    },
    [t('titles.socialConnections')]: {
      iconInactivePath: SoIconB,
      iconActivePath: SoIconG,
      iconPath: SoIconG,
      title: t('Data.socialConnections.subtitle'),
      description: t('Data.socialConnections.description'),
      bullets: t('Data.socialConnections.bullets', { returnObjects: true }),
      images: [SocialImg],
      buttonText: t('Data.socialConnections.buttonText'),
      buttonLink: t('Data.socialConnections.buttonLink'),
    },
    [t('titles.ecoImpact')]: {
      iconInactivePath: EcoIconB,
      iconActivePath: EcoIconG,
      iconPath: EcoIconG,
      title: t('Data.ecoImpact.subtitle'),
      description: t('Data.ecoImpact.description'),
      bullets: t('Data.ecoImpact.bullets', { returnObjects: true }),
      images: [EcoImg],
      buttonText: t('Data.ecoImpact.buttonText'),
      buttonLink: t('Data.ecoImpact.buttonLink'),
    },
    [t('titles.personalFulfillment')]: {
      iconInactivePath: PersoIconB,
      iconActivePath: PersoIconG,
      iconPath: PersoIconG,
      title: t('Data.personalFulfillment.subtitle'),
      description: t('Data.personalFulfillment.description'),
      bullets: t('Data.personalFulfillment.bullets', { returnObjects: true }),
      images: [PersoImg],
      buttonText: t('Data.personalFulfillment.buttonText'),
      buttonLink: t('Data.personalFulfillment.buttonLink'),
    }
  };

  const featureData = [
    {
      title: t('featureData.0.title'),
      content: t('featureData.0.content'),
      link: "/Solution/Gérez-vos-finances",
    },
    {
      title: t('featureData.1.title'),
      content: t('featureData.1.content'),
      link: "/Solution/Gérez-votre-temps",
    },
    {
      title: t('featureData.2.title'),
      content: t('featureData.2.content'),
      link: "/Solution/Gérez-votre-vie-administrive",
    },
    {
      title: t('featureData.3.title'),
      content: t('featureData.3.content'),
      link: "/Solution/Gérez-votre-immobilier-et-vos-véhicules",
    },
    {
      title: t('featureData.4.title'),
      content: t('featureData.4.content'),
      link: "/Solution/Gérez-vos-relations",
    },
    {
      title: t('featureData.5.title'),
      content: t('featureData.5.content'),
      link: "/Solution/Gérez-votre-carrière-pro",
    },
    {
      title: t('featureData.6.title'),
      content: t('featureData.6.content'),
      link: "/Solution/Gérez-votre-empreinte-carbone",
    },
    {
      title: t('featureData.7.title'),
      content: t('featureData.7.content'),
      link: "Solution/Gérez-votre-santé-sport-alimentation-spiritualité",
    },
  ];

  const dashboardItems = [
    {
      title: t('dashboardItems.partnerTitle'),
      description: t('dashboardItems.partnerDescription'),
      buttonText: t('dashboardItems.partnerButtonText'),
      buttonLink: '/Partenaire',
      imageUrl: PartnerImg,
    },
    {
      title: t('dashboardItems.fundraisingTitle'),
      description: t('dashboardItems.fundraisingDescription'),
      buttonText: t('dashboardItems.fundraisingButtonText'),
      buttonLink: '/rejoignez-nous/devenez-actionnaire',
      imageUrl: LeveImg,
    },
    {
      title: t('dashboardItems.releaseTitle'),
      description: t('dashboardItems.releaseDescription'),
      buttonText: t('dashboardItems.releaseButtonText'),
      buttonLink: '/roadmap',
      imageUrl: Release1Img,
    },
  ];

  const advantagesList = [
    {
      icon: PersonnalisationImg,
      title: t('advantages.personalizationTitle'),
      description: t('advantages.personalizationDescription'),
    },
    {
      icon: InteImg,
      title: t('advantages.integrationTitle'),
      description: t('advantages.integrationDescription'),
    },
    {
      icon: AccessImg,
      title: t('advantages.accessibilityTitle'),
      description: t('advantages.accessibilityDescription'),
    },
    {
      icon: FiabiliteImg,
      title: t('advantages.reliabilityTitle'),
      description: t('advantages.reliabilityDescription'),
    },
    {
      icon: EvoImg,
      title: t('advantages.scalabilityTitle'),
      description: t('advantages.scalabilityDescription'),
    },
  ];

  const faq = [
    {
      question: t('faq.questions.0.question'),
      answer: t('faq.questions.0.answer'),
    },
    {
      question: t('faq.questions.1.question'),
      answer: t('faq.questions.1.answer'),
    },
    {
      question: t('faq.questions.2.question'),
      answer: t('faq.questions.2.answer'),
    },
    {
      question: t('faq.questions.3.question'),
      answer: t('faq.questions.3.answer'),
    },
    {
      question: t('faq.questions.4.question'),
      answer: t('faq.questions.4.answer'),
    },
    {
      question: t('faq.questions.5.question'),
      answer: t('faq.questions.5.answer'),
    },
    {
      question: t('faq.questions.6.question'),
      answer: t('faq.questions.6.answer'),
    },
    {
      question: t('faq.questions.7.question'),
      answer: t('faq.questions.7.answer'),
    },
    {
      question: t('faq.questions.8.question'),
      answer: t('faq.questions.8.answer'),
    },
    {
      question: t('faq.questions.9.question'),
      answer: t('faq.questions.9.answer'),
    }
  ];


  return (
    <main className="App">
      <Helmet>
        <title>{t('app.title')}</title>
        <meta name="title" content={t('app.title')} />
        <meta name="description" content={t('app.description')} />
        <link rel="canonical" href="https://be-lifer.com/" />
        <meta name="author" content="Be-lifer" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('app.title')} />
        <meta property="og:description" content={t('app.description')} />
        <meta property="og:url" content="https://www.be-lifer.com/" />
        <meta property="og:site_name" content="Be-Lifer" />
      </Helmet>
        <Encart 
            title={t('header.manageLife')}
            content={t('header.manageLifeContent')}
            image={GestionImage}
        />
        <TextBanner
            title={t('textBanner.title')}
            content={t('textBanner.content')}
            level="h2"
        />
      <section> 
        <div className="carousel-and-banner-container">
          <Carousel slides={slides} />
          <div className="carousel-dots-container"></div> 
        </div>
      </section>
      <section>   
        <Advantages advantagesList={advantagesList} level="h3" /> 
      </section>
      <section> 
        <ModulesFeat data={Data} />
      </section>
      <section>   
        <TestBanner />
      </section>
      <section> 
        <UsBanner
          title={t('usBanner.title')}
          text={t('usBanner.content')}
          link={t('usBanner.link')}
          imageUrl={LifeBreak}
          level="h4"
        />
      </section>
      <section>   
        <TestBanner />
      </section>
      <section> 
        <h5>{t('faq.title')}</h5>
        <div>
          <Accordion faq={faq} />
        </div>
      </section>
      <section> 
        <ContactBanner
          chatText={t('contactBanner.chatText')}
          contactText={t('contactBanner.contactText')}
          phoneLabel={t('contactBanner.phoneLabel')}
          contactLink={t('contactBanner.contactLink')}
        />
      </section>
      <section>   
        <RoadMapComponent  />
      </section>
      <section> 
        <HeroBanner
          title={t('heroBanner.title')}
          subtitle={t('heroBanner.subtitle')}
          buttonText={t('heroBanner.buttonText')}
          buttonLink={t('heroBanner.buttonLink')}
          backgroundImage={BackImg}
        />
      </section>
    </main>
  );
}

export default Accueil;
