import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/Produit/Pro.webp';
import TestBanner from '../../Components/TestBanner';
import Features from '../../Components/FeatureSection';
import BackPro from '../../Images/Produit/ProBack.webp';
import slide1 from '../../Images/Produit/ProScreen.webp'; 
import slide2 from '../../Images/Produit/ProScreen2.webp'; 
import Accordion from '../../Components/Accordion';
import TextBanner from '../../Components/TextBanner';
import HeroBanner from '../../Components/HeroBanner';
import Carousel from '../../Components/Carousel';
import ImageTime from '../../Images/Produit/Time.webp';
import ImageDomicile from '../../Images/Produit/Domicile.webp';
import ImageFinance from '../../Images/Produit/Finance.webp';
import ImageAdmin from '../../Images/Produit/Admin.webp';
import ImageEco from '../../Images/Produit/Eco.webp';
import ImagePerso from '../../Images/Produit/Perso.webp';
import ImageSocial from '../../Images/Produit/Social.webp';
import ImageGeneral from '../../Images/Produit/General.webp';
import MonCarrousel from '../../Components/MonCarrousel';
import Advantages from '../../Components/Advantage';
import Vision from '../../Images/Produit/Vision.webp';
import Connectivite from '../../Images/Produit/Connectivite.webp';
import Reseau from '../../Images/Produit/Reseau.webp';
import { Helmet } from 'react-helmet';
import '../../Style/Produit.css';

const Pro = () => {
  const advantagesList = [
    {
      icon: Vision,
      title: 'Vision',
      description: "Progressez vers vos objectifs de carrière avec des plans d'action personnalisés et orientés vers le succès.",
    },
    {
      icon: Connectivite,
      title: 'Connectivité',
      description: "Accédez à une multitude d'opportunités adaptées à vos compétences et aspirations pour une carrière sur mesure.",
    },
    {
      icon: Reseau,
      title: 'Réseau',
      description: 'Développez et entretenez votre réseau professionnel, un pilier crucial pour votre évolution de carrière.',
    },
  ];

  const pageData = {
    title: 'NOS AUTRES SOLUTIONS',
    items: [
      {
        imageUrl: ImageSocial,
        title: 'Les relations sociales',
        link: '/Solution/Gérez-vos-relations'
      },
      {
        imageUrl: ImageTime,
        title: 'Le temps',
        link: '/Solution/Gérez-votre-temps'
      },
      {
        imageUrl: ImageAdmin,
        title: "L'administrative",
        link: '/Solution/Gérez-votre-vie-administrive'
      },
      {
        imageUrl: ImagePerso,
        title: 'La vie perso',
        link: '/Solution/Gérez-votre-santé-sport-alimentation-spiritualité'
      },
      {
        imageUrl: ImageEco,
        title: 'Notre envirronnement',
        link: '/Solution/Gérez-votre-empreinte-carbone'
      },
      {
        imageUrl: ImageDomicile,
        title: 'Le domicile',
        link: '/Solution/Gérez-votre-immobilier-et-vos-véhicules'
      },
      {
        imageUrl: ImageFinance,
        title: "Les finances",
        link: '/Solution/Gérez-vos-finances'
      },
      {
        imageUrl: ImageGeneral,
        title: "L'intégration",
        link: '/Solution'
      },
    ]
  };

  const featureData = [
    {
      title: "Chasseur d'Opportunités",
      content: "Recherchez et filtrez des offres d'emploi ou missions freelance selon vos compétences, intérêts, localisation et plus. Intégrez avec des plateformes d'emploi pour une recherche étendue et une candidature facilitée.",
      link: "/Solution/Gérez-votre-carrière-pro",
    },
    {
      title: "Pilote de Candidature",
      content: "Suivez l'état de vos candidatures, planifiez des entretiens et recevez des rappels pour les prochaines étapes, comme l'envoi de lettres de remerciement, après avoir postulé à des offres.",
      link: "/Solution/Gérez-votre-carrière-pro",
    },
    {
      title: "Gestionnaire d'Académie",
      content: "Ajoutez, suivez et renouvelez vos formations et certifications. Configurez des rappels pour les échéances de renouvellement et recevez des suggestions de formations adaptées à votre parcours professionnel.",
      link: "/Solution/Gérez-votre-carrière-pro",
    },
    {
      title: "Navigateur de Carrière",
      content: "Définissez vos objectifs à court, moyen et long terme. Établissez des jalons et des plans d'action, et recevez des rappels et mises à jour régulières pour rester sur la bonne voie vers l'atteinte de vos objectifs",
      link: "/Solution/Gérez-votre-carrière-pro",
    },
    {
      title: "Connecteur Professionnel",
      content: "Gérez et développez votre réseau professionnel. Suivez des contacts, planifiez des rendez-vous de networking et intégrez avec des plateformes professionnelles comme LinkedIn pour maximiser vos opportunités.",
      link: "/Solution/Gérez-votre-carrière-pro",
    },
    {
      title: "Activités pro.",
      content: "Suivez en temps réel votre activité professionnelle : enregistrez vos heures de travail, suivez votre chiffre d'affaires, gérez vos congés et autres absences. L'application offre une vue d'ensemble complète de votre performance et bien-être au travail, avec des rapports personnalisés et des analyses pour une gestion efficace de votre activité professionnelle.",
      link: "/Solution/Gérez-votre-carrière-pro",
    },
    {
      title: "Dashboard pro.",
      content: "Gérez efficacement votre carrière avec notre dashboard intégré : suivi du travail, chiffre d'affaires, congés, recherche d'emploi, suivi des candidatures, gestion des formations et des objectifs professionnels, et développement du réseau professionnel, le tout centralisé pour une gestion optimale de votre vie professionnelle.",
      link: "/Solution/Gérez-votre-carrière-pro",
    },
  ];

  const slides = [
    { src: slide1, type: 'img' },
  ];

  const slides2 = [
    { src: slide2, type: 'img' },
  ];

    return (
      <div>
        <Helmet>
          <title>Be-lifer, Propulsez Votre Carrière avec Life</title>
          <meta name="title" content="Be-lifer, Propulsez Votre Carrière avec Life"/>
          <meta name="description" content="Boostez votre carrière avec Life : formations, réseautage, et opportunités pour une trajectoire ascendante." />
          <link rel="canonical" href="https://be-lifer.com/Solution/Gérez-votre-carrière-pro"/>
        </Helmet>
        <Encart 
          title="La carrière professionnelle" 
          content="La carrières professionnelle est la toile de l'ambition et de la réalisation, peinte avec les couleurs de la passion et du dévouement. Elle représente une mosaïque d'expériences et de compétences, formant une œuvre unique de progrès personnel et de contribution significative au monde." 
          image={Image}
        />
        <TestBanner />
        <TextBanner
          title = "Fournir des outils pour le développement professionnel et la gestion de carrière."
          content = "Développez votre carrière et saisissez des opportunités de formation continue et de réseautage professionnel grâce à notre système de gestion intégré. Notre plateforme consolide vos aspirations professionnelles, identifie des parcours de formation pertinents et favorise des connexions de réseau stratégiques, le tout en parfaite synergie avec d'autres modules pour une évolution de carrière fluide et harmonieuse. Cultivez votre potentiel, élargissez votre expertise et propulsez votre trajectoire professionnelle vers de nouveaux sommets."
        />
        
        <Carousel slides={slides} />
        <Advantages advantagesList={advantagesList} /> 
        <TestBanner />
        <Carousel slides={slides2} />
        <TestBanner />
        <h1>Des solutions conçues pour vous</h1>
        <Features features={featureData} />
        <TestBanner />
        <HeroBanner
          title="Impulsez votre carrière"
          subtitle="Atteignez vos sommets."
          buttonText="Démarrer gratuitement"
          buttonLink="https://be-lifer.com/rejoignez-nous/Devenez-Testeur"
          backgroundImage={BackPro}
        />
        
        <MonCarrousel title={pageData.title} items={pageData.items} visibleItemsCount={3}  />
      </div>
    );
};

export default Pro;