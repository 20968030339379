import React from 'react';
import HeroSection from '../../Components/HeroSection'; 
import ImageGeneral from '../../Images/About/Actionnaire.webp';
import ImageFinance from '../../Images/About/Partenaire.webp';
import ImageSocial from '../../Images/About/Job.webp';
import ImageTime from '../../Images/About/Testeur.webp';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';


const Produit = () => {
  const { t } = useTranslation('about');
  // Les données spécifiques à ta page
  const pageData = [
    {
      title: t('partenaireGeneral.pageData.0.title'),
      subtitle: t('partenaireGeneral.pageData.0.subtitle'),
      buttonText: t('partenaireGeneral.pageData.0.buttonText'),
      buttonLink: t('partenaireGeneral.pageData.0.buttonLink'),
      backgroundImage: ImageGeneral
    },
    {
      title: t('partenaireGeneral.pageData.1.title'),
      subtitle: t('partenaireGeneral.pageData.1.subtitle'),
      buttonText: t('partenaireGeneral.pageData.1.buttonText'),
      buttonLink: t('partenaireGeneral.pageData.1.buttonLink'),
      backgroundImage: ImageFinance
    },
    {
      title: t('partenaireGeneral.pageData.2.title'),
      subtitle: t('partenaireGeneral.pageData.2.subtitle'),
      buttonText: t('partenaireGeneral.pageData.2.buttonText'),
      buttonLink: t('partenaireGeneral.pageData.2.buttonLink'),
      backgroundImage: ImageTime
    },
    {
      title: t('partenaireGeneral.pageData.3.title'),
      subtitle: t('partenaireGeneral.pageData.3.subtitle'),
      buttonText: t('partenaireGeneral.pageData.3.buttonText'),
      buttonLink: t('partenaireGeneral.pageData.3.buttonLink'),
      backgroundImage: ImageSocial
    }
  ];

  return (
    <>
      <Helmet>
        <title>{t('partenaireGeneral.pageTitle')}</title>
        <meta name="description" content={t('partenaireGeneral.pageMetaDescription')} />
        <link rel="canonical" href="https://be-lifer.com/rejoignez-nous" />
      </Helmet>
      <div>
        {pageData.map((data, index) => (
          <HeroSection 
            key={index}
            className="heroSection"
            style={{ zIndex: 10 }}
            title={data.title}
            subtitle={data.subtitle}
            buttonText={data.buttonText}
            backgroundImage={data.backgroundImage}
            buttonLink={data.buttonLink}
          />
        ))}
      </div>
    </>
  );
};

export default Produit;
