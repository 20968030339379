import React from 'react';
import PropTypes from 'prop-types';
import '../Style/Accueil.css'; // Assure-toi que le chemin est correct
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ContactBanner = ({ phone, chatText, contactText, phoneLabel, contactLink }) => {
  const { t } = useTranslation('common');

  return (
    <div className="contact-banner">
      <div className="contact-item">
        <div className="h6">{t('contactBanner.contactUsTitle')}</div>
        <p>{contactText}</p>
        <Link to="/contactus">{t('contactBanner.sendMessageLinkText')}</Link>
      </div>
    </div>
  );
};

ContactBanner.propTypes = {
  phone: PropTypes.string.isRequired,
  chatText: PropTypes.string.isRequired,
  contactText: PropTypes.string.isRequired,
  phoneLabel: PropTypes.string,
  contactLink: PropTypes.string.isRequired,
};

export default ContactBanner;
