import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/Produit/Social.webp';
import TestBanner from '../../Components/TestBanner';
import BackSocial from '../../Images/Produit/SocialBack.webp';
import Features from '../../Components/FeatureSection';
import slide1 from '../../Images/Produit/SocialScreen.webp'; 
import slide2 from '../../Images/Produit/SocialScreen2.webp'; 
import Accordion from '../../Components/Accordion';
import TextBanner from '../../Components/TextBanner';
// import SliderComponent from '../../Components/SliderComponent';
import HeroBanner from '../../Components/HeroBanner';
import ImageTime from '../../Images/Produit/Time.webp';
import ImageDomicile from '../../Images/Produit/Domicile.webp';
import ImageFinance from '../../Images/Produit/Finance.webp';
import ImageAdmin from '../../Images/Produit/Admin.webp';
import ImageEco from '../../Images/Produit/Eco.webp';
import ImagePerso from '../../Images/Produit/Perso.webp';
import ImagePro from '../../Images/Produit/Pro.webp';
import ImageGeneral from '../../Images/Produit/General.webp';
import Carousel from '../../Components/Carousel';
import MonCarrousel from '../../Components/MonCarrousel';
import Advantages from '../../Components/Advantage';
import Organisation from '../../Images/Produit/Organisation.webp';
import Centralisation from '../../Images/Produit/Centralisation.webp';
import Simplicite from '../../Images/Produit/Simplicité.webp';
import { Helmet } from 'react-helmet';
import '../../Style/Produit.css';

const Social = () => {
  const advantagesList = [
    {
      icon: Organisation,
      title: 'Organisation',
      description: "Gérez efficacement vos relations grâce à une gestion des contacts avancée, des catégorisations claires et des rappels d'événements personnels",
    },
    {
      icon: Centralisation,
      title: 'Centralisation',
      description: "La vue consolidée des notifications et mises à jour des réseaux sociaux, ainsi que l'intégration avec d'autres modules, créent une expérience utilisateur plus complète et pratique.",
    },
    {
      icon: Simplicite,
      title: 'Simplicité',
      description: 'Planifiez et gérez vos événements avec aisance grâce à des outils intégrés pour les invitations, les rappels et la billetterie.',
    },
  ];
  const pageData = {
    title: 'NOS AUTRES SOLUTIONS',
    items: [
      {
        imageUrl: ImageTime,
        title: 'Le temps',
        link: '/Solution/Gérez-votre-temps'
      },
      {
        imageUrl: ImageAdmin,
        title: "L'administrative",
        link: '/Solution/Gérez-votre-vie-administrive'
      },
      {
        imageUrl: ImagePro,
        title: 'La carrière pro',
        link: '/Solution/Gérez-votre-carrière-pro'
      },
      {
        imageUrl: ImagePerso,
        title: 'La vie perso',
        link: '/Solution/Gérez-votre-santé-sport-alimentation-spiritualité'
      },
      {
        imageUrl: ImageEco,
        title: 'Notre envirronnement',
        link: '/Solution/Gérez-votre-empreinte-carbone'
      },
      {
        imageUrl: ImageDomicile,
        title: 'Le domicile',
        link: '/Solution/Gérez-votre-immobilier-et-vos-véhicules'
      },
      {
        imageUrl: ImageFinance,
        title: "Les finances",
        link: '/Solution/Gérez-vos-finances'
      },
      {
        imageUrl: ImageGeneral,
        title: "L'intégration",
        link: '/Solution'
      },
    ]
  };

  const featureData = [
    {
      title: "Organisateur d'Événements",
      content: "Créez, planifiez et gérez des événements variés, de réunions professionnelles à des sorties entre amis. Ajoutez des invités, localisez des lieux, configurez des rappels et intégrez des plateformes de billetterie pour une organisation sans faille.",
      link: "/Solution/Gérez-vos-relations",
    },
    {
      title: "Synchronisateur d'Engagements",
      content: "Synchronisez automatiquement vos événements avec d'autres modules comme Finance ou Time pour gérer tous les engagements financiers ou temporels. Recevez des rappels pour des tâches telles que l'achat de cadeaux d'anniversaire ou la planification de temps pour des événements à venir.",
      link: "/Solution/Gérez-vos-relations",
    },
    {
      title: "Répertoire Dynamique",
      content: "Enregistrez et gérez vos contacts en ajoutant des détails comme les numéros de téléphone, adresses, anniversaires, et notes. Utilisez des fonctions de recherche et de tri pour une navigation facile et efficace.",
      link: "/Solution/Gérez-vos-relations",
    },
    {
      title: "Connecteur Social",
      content: "Intégrez vos principaux réseaux sociaux pour voir les mises à jour de contacts, importer des événements, et partager directement depuis l'application. Bénéficiez d'une vue consolidée de toutes vos notifications et mises à jour sur les réseaux sociaux.",
      link: "/Solution/Gérez-vos-relations",
    },
  ];

  const slides = [
    { src: slide1, type: 'img' },
  ];

  const slides2 = [
    { src: slide2, type: 'img' },
  ];

    return (
      <div>
        <Helmet>
          <title>Be-lifer, Optimisez Vos Relations Sociales avec Life</title>
          <meta name="title" content="Be-lifer, Optimisez Vos Relations Sociales avec Life"/>
          <meta name="description" content="Gérez vos contacts et événements avec Life : enrichissez votre réseau social et contribuez à votre communauté." />
          <link rel="canonical" href="https://be-lifer.com/Solution/Gérez-vos-relations"/>
        </Helmet>
        <Encart 
          title="Les relations sociales" 
          content="Les relations sociales sont la trame de notre humanité, tissées avec les fils de la sincérité et enrichies par la diversité. Nos liens avec autrui sont des joyaux d'authenticité, façonnant un réseau de connexions qui reflètent la quintessence de la vie en société." 
          image={Image}
        />
        <TestBanner />
        <TextBanner
          title = "Améliorer les interactions et les connexions sociales"
          content = "Optimisez vos interactions sociales grâce à une gestion centralisée de vos contacts et événements, tout en bénéficiant d'une intégration transparente avec d'autres modules essentiels. Notre plateforme vous permet d'orchestrer vos engagements sociaux, de cultiver vos relations et de maximiser vos opportunités de réseautage avec une facilité et une efficacité inégalées. Enrichissez votre vie sociale, tout en apportant une contribution significative à votre communauté grâce à une gestion plus intelligente et connectée."
        />
        
        <Carousel slides={slides} />
        <Advantages advantagesList={advantagesList} /> 
        <TestBanner />
        <Carousel slides={slides2} />
        <TestBanner />
        <h1>Des solutions conçues pour vous</h1>
        <Features features={featureData} />
        <HeroBanner
          title="Enrichissez vos relations"
          subtitle="Embellissez votre vie sociale"
          buttonText="Démarrer gratuitement"
          buttonLink="https://be-lifer.com/rejoignez-nous/Devenez-Testeur"
          backgroundImage={BackSocial}
        />
        
        <MonCarrousel title={pageData.title} items={pageData.items} visibleItemsCount={3}  />
      </div>
    );
};

export default Social;