import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import TextBanner from '../Components/TextBanner';
import Carousel from '../Components/Carousel'
import slide1 from '../Images/Roadmap/RoadmapGeneral.webp'
import { RoadmapCont } from '../Components/RoadmapCont'
import Phase0img from '../Images/Roadmap/Phase0.webp'
import TeamPhase0img from '../Images/Roadmap/TeamPhase0.webp'
import Phase1img from '../Images/Roadmap/Phase1.webp'
import TeamPhase1img from '../Images/Roadmap/TeamPhase1.webp'
import Phase3img from '../Images/Roadmap/Phase3.webp'
import Phase4img from '../Images/Roadmap/Phase4.webp'
import Phase5img from '../Images/Roadmap/Phase5.webp'
import Constructionimg from '../Images/Roadmap/Construction.webp'
import HeroBanner from '../Components/HeroBanner';
import BackImg from '../Images/Accueil/Background.webp';
import Advantages from '../Components/Advantage';
import PersonnalisationImg from '../Images/Accueil/Custom.webp';
import FiabiliteImg from '../Images/Accueil/Fiabilité.webp';
import EvoImg from '../Images/Accueil/Evo.webp';
import AccessImg from '../Images/Accueil/Access.webp';
import InteImg from '../Images/Accueil/Inte.webp';
import { useTranslation } from 'react-i18next';

const Roadmap = () => {
  const { t } = useTranslation('roadmap');
  const advantagesList = [
    {
      icon: PersonnalisationImg,
      title: t('roadmap.advantagesList.0.title'),
      description: t('roadmap.advantagesList.0.description'),
    },
    {
      icon: InteImg,
      title: t('roadmap.advantagesList.1.title'),
      description: t('roadmap.advantagesList.1.description'),
    },
    {
      icon: AccessImg,
      title: t('roadmap.advantagesList.2.title'),
      description: t('roadmap.advantagesList.2.description'),
    },
    {
      icon: FiabiliteImg,
      title: t('advantagesList.3.title'),
      description: t('advantagesList.3.description'),
    },
    {
      icon: EvoImg,
      title: t('roadmap.advantagesList.4.title'),
      description: t('roadmap.advantagesList.4.description'),
    },
  ];

    const slides = [
        { src: slide1, type: 'img' },
      ];
    
      const faqsData = [
        {
          question: t('roadmap.faqs.0.question'),
          image: Phase0img,
          introText: t('roadmap.faqs.0.introText'),
          data: [
            {
              domaine: t('roadmap.faqs.0.data.0.domaine'),
              solution: t('roadmap.faqs.0.data.0.solution'),
              titre: t('roadmap.faqs.0.data.0.titre'),
              description: t('roadmap.faqs.0.data.0.description'),
              categorie: t('roadmap.faqs.0.data.0.categorie'),
            },
            {
              domaine: t('roadmap.faqs.0.data.1.domaine'),
              solution: t('roadmap.faqs.0.data.1.solution'),
              titre: t('roadmap.faqs.0.data.1.titre'),
              description: t('roadmap.faqs.0.data.1.description'),
              categorie: t('roadmap.faqs.0.data.1.categorie'),
            },
            {
              domaine: t('roadmap.faqs.0.data.2.domaine'),
              solution: t('roadmap.faqs.0.data.2.solution'),
              titre: t('roadmap.faqs.0.data.2.titre'),
              description: t('roadmap.faqs.0.data.2.description'),
              categorie: t('roadmap.faqs.0.data.2.categorie'),
            },
          ],
          introText2: t('roadmap.faqs.0.introText2'),
          image2: TeamPhase0img,
        },
        {
          question: t('roadmap.faqs.1.question'),
          image: Phase1img,
          introText: t('roadmap.faqs.1.introText'),
          data: [
            {
              domaine: t('roadmap.faqs.1.data.0.domaine'),
              solution: t('roadmap.faqs.1.data.0.solution'),
              titre: t('roadmap.faqs.1.data.0.titre'),
              description: t('roadmap.faqs.1.data.0.description'),
              categorie: t('roadmap.faqs.1.data.0.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.1.domaine'),
              solution: t('roadmap.faqs.1.data.1.solution'),
              titre: t('roadmap.faqs.1.data.1.titre'),
              description: t('roadmap.faqs.1.data.1.description'),
              categorie: t('roadmap.faqs.1.data.1.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.2.domaine'),
              solution: t('roadmap.faqs.1.data.2.solution'),
              titre: t('roadmap.faqs.1.data.2.titre'),
              description: t('roadmap.faqs.1.data.2.description'),
              categorie: t('roadmap.faqs.1.data.2.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.3.domaine'),
              solution: t('roadmap.faqs.1.data.3.solution'),
              titre: t('roadmap.faqs.1.data.3.titre'),
              description: t('roadmap.faqs.1.data.3.description'),
              categorie: t('roadmap.faqs.1.data.3.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.4.domaine'),
              solution: t('roadmap.faqs.1.data.4.solution'),
              titre: t('roadmap.faqs.1.data.4.titre'),
              description: t('roadmap.faqs.1.data.4.description'),
              categorie: t('roadmap.faqs.1.data.4.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.5.domaine'),
              solution: t('roadmap.faqs.1.data.5.solution'),
              titre: t('roadmap.faqs.1.data.5.titre'),
              description: t('roadmap.faqs.1.data.5.description'),
              categorie: t('roadmap.faqs.1.data.5.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.6.domaine'),
              solution: t('roadmap.faqs.1.data.6.solution'),
              titre: t('roadmap.faqs.1.data.6.titre'),
              description: t('roadmap.faqs.1.data.6.description'),
              categorie: t('roadmap.faqs.1.data.6.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.7.domaine'),
              solution: t('roadmap.faqs.1.data.7.solution'),
              titre: t('roadmap.faqs.1.data.7.titre'),
              description: t('roadmap.faqs.1.data.7.description'),
              categorie: t('roadmap.faqs.1.data.7.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.8.domaine'),
              solution: t('roadmap.faqs.1.data.8.solution'),
              titre: t('roadmap.faqs.1.data.8.titre'),
              description: t('roadmap.faqs.1.data.8.description'),
              categorie: t('roadmap.faqs.1.data.8.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.9.domaine'),
              solution: t('roadmap.faqs.1.data.9.solution'),
              titre: t('roadmap.faqs.1.data.9.titre'),
              description: t('roadmap.faqs.1.data.9.description'),
              categorie: t('roadmap.faqs.1.data.9.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.10.domaine'),
              solution: t('roadmap.faqs.1.data.10.solution'),
              titre: t('roadmap.faqs.1.data.10.titre'),
              description: t('roadmap.faqs.1.data.10.description'),
              categorie: t('roadmap.faqs.1.data.10.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.11.domaine'),
              solution: t('roadmap.faqs.1.data.11.solution'),
              titre: t('roadmap.faqs.1.data.11.titre'),
              description: t('roadmap.faqs.1.data.11.description'),
              categorie: t('roadmap.faqs.1.data.11.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.12.domaine'),
              solution: t('roadmap.faqs.1.data.12.solution'),
              titre: t('roadmap.faqs.1.data.12.titre'),
              description: t('roadmap.faqs.1.data.12.description'),
              categorie: t('roadmap.faqs.1.data.12.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.13.domaine'),
              solution: t('roadmap.faqs.1.data.13.solution'),
              titre: t('roadmap.faqs.1.data.13.titre'),
              description: t('roadmap.faqs.1.data.13.description'),
              categorie: t('roadmap.faqs.1.data.13.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.14.domaine'),
              solution: t('roadmap.faqs.1.data.14.solution'),
              titre: t('roadmap.faqs.1.data.14.titre'),
              description: t('roadmap.faqs.1.data.14.description'),
              categorie: t('roadmap.faqs.1.data.14.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.15.domaine'),
              solution: t('roadmap.faqs.1.data.15.solution'),
              titre: t('roadmap.faqs.1.data.15.titre'),
              description: t('roadmap.faqs.1.data.15.description'),
              categorie: t('roadmap.faqs.1.data.15.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.16.domaine'),
              solution: t('roadmap.faqs.1.data.16.solution'),
              titre: t('roadmap.faqs.1.data.16.titre'),
              description: t('roadmap.faqs.1.data.16.description'),
              categorie: t('roadmap.faqs.1.data.16.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.17.domaine'),
              solution: t('roadmap.faqs.1.data.17.solution'),
              titre: t('roadmap.faqs.1.data.17.titre'),
              description: t('roadmap.faqs.1.data.17.description'),
              categorie: t('roadmap.faqs.1.data.17.categorie'),
            },
            {
              domaine: t('roadmap.faqs.1.data.18.domaine'),
              solution: t('roadmap.faqs.1.data.18.solution'),
              titre: t('roadmap.faqs.1.data.18.titre'),
              description: t('roadmap.faqs.1.data.18.description'),
              categorie: t('roadmap.faqs.1.data.18.categorie'),
            },
          ],
          introText2: t('roadmap.faqs.1.introText2'),
          image2: TeamPhase1img,
        },
        {
          question: t('roadmap.faqs.2.question'),
          image: Phase3img,
          introText: t('roadmap.faqs.2.introText'),
          data: [
            {
              domaine: t('roadmap.faqs.2.data.0.domaine'),
              solution: t('roadmap.faqs.2.data.0.solution'),
              titre: t('roadmap.faqs.2.data.0.titre'),
              description: t('roadmap.faqs.2.data.0.description'),
              categorie: t('roadmap.faqs.2.data.0.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.1.domaine'),
              solution: t('roadmap.faqs.2.data.1.solution'),
              titre: t('roadmap.faqs.2.data.1.titre'),
              description: t('roadmap.faqs.2.data.1.description'),
              categorie: t('roadmap.faqs.2.data.1.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.2.domaine'),
              solution: t('roadmap.faqs.2.data.2.solution'),
              titre: t('roadmap.faqs.2.data.2.titre'),
              description: t('roadmap.faqs.2.data.2.description'),
              categorie: t('roadmap.faqs.2.data.2.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.3.domaine'),
              solution: t('roadmap.faqs.2.data.3.solution'),
              titre: t('roadmap.faqs.2.data.3.titre'),
              description: t('roadmap.faqs.2.data.3.description'),
              categorie: t('roadmap.faqs.2.data.3.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.4.domaine'),
              solution: t('roadmap.faqs.2.data.4.solution'),
              titre: t('roadmap.faqs.2.data.4.titre'),
              description: t('roadmap.faqs.2.data.4.description'),
              categorie: t('roadmap.faqs.2.data.4.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.5.domaine'),
              solution: t('roadmap.faqs.2.data.5.solution'),
              titre: t('roadmap.faqs.2.data.5.titre'),
              description: t('roadmap.faqs.2.data.5.description'),
              categorie: t('roadmap.faqs.2.data.5.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.6.domaine'),
              solution: t('roadmap.faqs.2.data.6.solution'),
              titre: t('roadmap.faqs.2.data.6.titre'),
              description: t('roadmap.faqs.2.data.6.description'),
              categorie: t('roadmap.faqs.2.data.6.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.7.domaine'),
              solution: t('roadmap.faqs.2.data.7.solution'),
              titre: t('roadmap.faqs.2.data.7.titre'),
              description: t('roadmap.faqs.2.data.7.description'),
              categorie: t('roadmap.faqs.2.data.7.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.8.domaine'),
              solution: t('roadmap.faqs.2.data.8.solution'),
              titre: t('roadmap.faqs.2.data.8.titre'),
              description: t('roadmap.faqs.2.data.8.description'),
              categorie: t('roadmap.faqs.2.data.8.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.9.domaine'),
              solution: t('roadmap.faqs.2.data.9.solution'),
              titre: t('roadmap.faqs.2.data.9.titre'),
              description: t('roadmap.faqs.2.data.9.description'),
              categorie: t('roadmap.faqs.2.data.9.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.10.domaine'),
              solution: t('roadmap.faqs.2.data.10.solution'),
              titre: t('roadmap.faqs.2.data.10.titre'),
              description: t('roadmap.faqs.2.data.10.description'),
              categorie: t('roadmap.faqs.2.data.10.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.11.domaine'),
              solution: t('roadmap.faqs.2.data.11.solution'),
              titre: t('roadmap.faqs.2.data.11.titre'),
              description: t('roadmap.faqs.2.data.11.description'),
              categorie: t('roadmap.faqs.2.data.11.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.12.domaine'),
              solution: t('roadmap.faqs.2.data.12.solution'),
              titre: t('roadmap.faqs.2.data.12.titre'),
              description: t('roadmap.faqs.2.data.12.description'),
              categorie: t('roadmap.faqs.2.data.12.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.13.domaine'),
              solution: t('roadmap.faqs.2.data.13.solution'),
              titre: t('roadmap.faqs.2.data.13.titre'),
              description: t('roadmap.faqs.2.data.13.description'),
              categorie: t('roadmap.faqs.2.data.13.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.14.domaine'),
              solution: t('roadmap.faqs.2.data.14.solution'),
              titre: t('roadmap.faqs.2.data.14.titre'),
              description: t('roadmap.faqs.2.data.14.description'),
              categorie: t('roadmap.faqs.2.data.14.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.15.domaine'),
              solution: t('roadmap.faqs.2.data.15.solution'),
              titre: t('roadmap.faqs.2.data.15.titre'),
              description: t('roadmap.faqs.2.data.15.description'),
              categorie: t('roadmap.faqs.2.data.15.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.16.domaine'),
              solution: t('roadmap.faqs.2.data.16.solution'),
              titre: t('roadmap.faqs.2.data.16.titre'),
              description: t('roadmap.faqs.2.data.16.description'),
              categorie: t('roadmap.faqs.2.data.16.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.17.domaine'),
              solution: t('roadmap.faqs.2.data.17.solution'),
              titre: t('roadmap.faqs.2.data.17.titre'),
              description: t('roadmap.faqs.2.data.17.description'),
              categorie: t('roadmap.faqs.2.data.17.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.18.domaine'),
              solution: t('roadmap.faqs.2.data.18.solution'),
              titre: t('roadmap.faqs.2.data.18.titre'),
              description: t('roadmap.faqs.2.data.18.description'),
              categorie: t('roadmap.faqs.2.data.18.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.19.domaine'),
              solution: t('roadmap.faqs.2.data.19.solution'),
              titre: t('roadmap.faqs.2.data.19.titre'),
              description: t('roadmap.faqs.2.data.19.description'),
              categorie: t('roadmap.faqs.2.data.19.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.20.domaine'),
              solution: t('roadmap.faqs.2.data.20.solution'),
              titre: t('roadmap.faqs.2.data.20.titre'),
              description: t('roadmap.faqs.2.data.20.description'),
              categorie: t('roadmap.faqs.2.data.20.categorie'),
            },
            {
              domaine: t('roadmap.faqs.2.data.21.domaine'),
              solution: t('roadmap.faqs.2.data.21.solution'),
              titre: t('roadmap.faqs.2.data.21.titre'),
              description: t('roadmap.faqs.2.data.21.description'),
              categorie: t('roadmap.faqs.2.data.21.categorie'),
            },
            
          ],
          introText2: t('roadmap.faqs.2.introText2'),
          image2: Constructionimg,
        },
        {
          question: t('roadmap.faqs.3.question'),
          image: Phase4img,
          introText: t('roadmap.faqs.3.introText'),
          // data: [
          //   {
          //     domaine: 'Terre',
          //     solution: "Consommations",
          //     titre: "Suivi consommations",
          //     description: "Intègre tickets de caisse électroniques pour un suivi des dépenses et impact sur l'empreinte carbone.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Terre',
          //     solution: "Empreinte carbone",
          //     titre: "Compensations carbone",
          //     description: "Intègre des solutions de compensation carbone dans le calcul de l'empreinte écologique.",
          //     categorie: 'Amélioration',
          //   },
          //   {
          //     domaine: 'Terre',
          //     solution: "Empreinte carbone",
          //     titre: "Conseiller vert",
          //     description: "Conseils personnalisés basés sur la sensibilité environnementale de l'utilisateur.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Général',
          //     solution: "Notifications intelligente",
          //     titre: "Notification",
          //     description: " Notifications intelligentes et prédictives couvrant plusieurs domaines.",
          //     categorie: 'Amélioration',
          //   },
          //   {
          //     domaine: 'Finance',
          //     solution: "Comptabilité",
          //     titre: "Opérations financières",
          //     description: "Intégration des factures aux opérations financières correspondantes.",
          //     categorie: 'Amélioration',
          //   },
          //   {
          //     domaine: 'Général',
          //     solution: "Pilotage",
          //     titre: "Synthèses financières",
          //     description: "Amélioration des synthèses financières pour une vue détaillée.",
          //     categorie: 'Fonctionalité',
          //   },
          //   {
          //     domaine: 'Général',
          //     solution: "Résumé",
          //     titre: "Récapitulatif",
          //     description: "Synthèses mensuelles ou hebdomadaires avec recommandations.",
          //     categorie: 'Fonctionalité',
          //   },
          //   {
          //     domaine: 'Général',
          //     solution: "Feedback",
          //     titre: "Feedback intégré et gestions nouvelles fonctionnalités",
          //     description: "Espace utilisateur pour influencer les futures améliorations.",
          //     categorie: 'Fonctionalité',
          //   },
          //   {
          //     domaine: 'Personnel',
          //     solution: "Santé",
          //     titre: "Ma santé",
          //     description: "Système de gestion de la santé intégré, incluant aspects médicaux et financiers.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Personnel',
          //     solution: "Sport",
          //     titre: "Mon activité",
          //     description: "Suivi d'activités sportives avec plans d'action et régimes.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Personnel',
          //     solution: "Spirituel",
          //     titre: "Journal de développement personnel",
          //     description: "Journal pour motiver et suivre le développement personnel.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Social',
          //     solution: "Spirituel",
          //     titre: "Mes messages",
          //     description: "Partage de messages et événements liés aux dépenses entre utilisateurs.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Temps',
          //     solution: "Recommandation",
          //     titre: "Conseils et recommandation",
          //     description: "Optimisation des conseils et recommandations personnalisés.",
          //     categorie: 'Amélioration',
          //   },
          //   {
          //     domaine: 'Pro',
          //     solution: "Activité",
          //     titre: "Mon activité",
          //     description: "Suivi de l'activité professionnelle, heures de travail et chiffre d'affaires.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Pro',
          //     solution: "Business",
          //     titre: "Mes business plan",
          //     description: "Assistance dans la création de business plans.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Pro',
          //     solution: "Suivi",
          //     titre: "Synthèse professionnelle",
          //     description: "Dashboard professionnel pour piloter la réussite des projets professionnels.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Pro',
          //     solution: "Satisfaction",
          //     titre: "Evaluation statisfaction",
          //     description: "Évaluation et conseils pour améliorer le bien-être professionnel.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Général',
          //     solution: "général",
          //     titre: "Général",
          //     description: "Améliorations continues et optimisations des fonctionnalités existantes.",
          //     categorie: 'Amélioration',
          //   },
          // ],
        introText2: t('roadmap.faqs.3.introText2'),
        image2: Constructionimg,
        },

        {
          question: t('roadmap.faqs.4.question'),
          image: Phase5img,
          introText:  t('roadmap.faqs.4.introText'),
          // data: [
          //   {
          //     domaine: 'Général',
          //     solution: "Recommandation",
          //     titre: "Recommation",
          //     description: "Offre des recommandations générales multi-modules pour une expérience utilisateur améliorée.",
          //     categorie: 'Fonctionnalité',
          //   },
          //   {
          //     domaine: 'Général',
          //     solution: "Mise à jour",
          //     titre: "Update",
          //     description: "Planifie les mises à jour en phase de sprint en fonction des retours utilisateurs pour une amélioration continue.",
          //     categorie: 'Fonctionnalité',
          //   },
          //   {
          //     domaine: 'Finance',
          //     solution: "Impôts",
          //     titre: "Ma fiscalité",
          //     description: "Suivi et optimisation fiscale pour gérer efficacement vos obligations et opportunités fiscales.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Finance',
          //     solution: "Business",
          //     titre: "Mon ERP light",
          //     description: "Un ERP simplifié pour entrepreneurs individuels ou TPE, facilitant la gestion d'entreprise.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Finance',
          //     solution: "Investissements",
          //     titre: "Gestion et pilotage actifs",
          //     description: "Gestion de portefeuilles boursiers, immobiliers ou d'entreprises pour un suivi optimal des investissements.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Temps',
          //     solution: "Pilotage",
          //     titre: "Calendrier",
          //     description: "Planification automatique et intelligente pour organiser efficacement votre emploi du temps.",
          //     categorie: 'Fonctionnalité',
          //   },
          //   {
          //     domaine: 'Domicile',
          //     solution: "Animal",
          //     titre: "Mes animaux",
          //     description: "Gestion des animaux domestiques, suivi des soins et intégration avec services vétérinaires.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Domicile',
          //     solution: "IoT",
          //     titre: "Mes objets connectés",
          //     description: "Suivi et gestion du parc d'objets connectés pour une meilleure utilisation et maintenance",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Personnel',
          //     solution: "Pilotage",
          //     titre: "Synthèse personnel",
          //     description: "Un dashboard personnel intégrant des aspects santé, sport, spirituel et loisirs pour un suivi complet de votre bien-être.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Terre',
          //     solution: "Alimentation",
          //     titre: "Carte produit frais",
          //     description: "Localisez facilement les produits frais les plus proches de vous.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Terre',
          //     solution: "Social",
          //     titre: "Défis écolo",
          //     description: "Lancez et participez à des défis écologiques avec vos amis pour une approche ludique de l'écologie.",
          //     categorie: 'Fonctionnalité',
          //   },
          //   {
          //     domaine: 'Social',
          //     solution: "général",
          //     titre: "Général",
          //     description: "Intègre la gestion familiale partagée pour une organisation familiale harmonieuse.",
          //     categorie: 'Fonctionnalité',
          //   },
          //   {
          //     domaine: 'Administratif',
          //     solution: "Pilotage",
          //     titre: "Synthèse adminsitratif",
          //     description: "Dashboard administratif pour gérer démarches, documents, demandes et contacts efficacement.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Administratif',
          //     solution: "Doucment",
          //     titre: "Mes contrats",
          //     description: "Gestion et intégration détaillée de vos contrats pour un suivi précis et sécurisé.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Administratif',
          //     solution: "Doucment",
          //     titre: "Mes documents",
          //     description: "Intégration et classification automatique de documents via photos pour une organisation documentaire simplifiée.",
          //     categorie: 'Fonctionnalité',
          //   },
          //   {
          //     domaine: 'Pro',
          //     solution: "Pilotage",
          //     titre: "Mon emploi",
          //     description: "Intégration et gestion des informations liées à votre emploi et employeur.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Pro',
          //     solution: "Pilotage",
          //     titre: "Ma retraite",
          //     description: "Outils et conseils pour une préparation optimale de votre retraite.",
          //     categorie: 'Application',
          //   },
          //   {
          //     domaine: 'Général',
          //     solution: "général",
          //     titre: "Général",
          //     description: "Améliorations continues et optimisations des fonctionnalités existantes pour une expérience utilisateur toujours plus riche et fluide.",
          //     categorie: 'Amélioration',
          //   },
          // ],
        introText2: t('roadmap.faqs.4.introText2'),
        image2: Constructionimg,
        },
        // ... autres objets FAQ
      ];
    
      return (
        <>
          <Helmet>
            <title>{t('roadmap.pageTitle')}</title>
            <meta name="title" content={t('roadmap.pageMetaTitle')} />
            <meta name="description" content={t('roadmap.pageMetaDescription')} />
            <link rel="canonical" href="https://be-lifer.com/Notre-feuille-de-route" />
            <meta name="author" content="Be-lifer" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={t('roadmap.pageMetaTitle')} />
            <meta property="og:description" content={t('roadmap.pageMetaDescription')} />
            <meta property="og:url" content="https://be-lifer.com/Notre-feuille-de-route" />
            <meta property="og:site_name" content="Be-Lifer" />
          </Helmet>
          <div>
            <TextBanner
              title={t('roadmap.textBannerTitle1')}
              content={t('roadmap.textBannerContent1')}
              level="h1"
            />
            <TextBanner
              title={t('roadmap.textBannerTitle2')}
              content={t('roadmap.textBannerContent2')}
              level="h2"
            />
            <Carousel slides={slides} />
            <RoadmapCont faqs={faqsData} />
            <Advantages advantagesList={advantagesList} level="h4" />
            <HeroBanner
              title={t('roadmap.heroBannerTitle')}
              subtitle={t('roadmap.heroBannerSubtitle')}
              buttonText={t('roadmap.heroBannerButtonText')}
              buttonLink={t('roadmap.heroBannerButtonLink')}
              backgroundImage={BackImg}
            />
          </div>
        </>
      );
    };

    export default Roadmap;