import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/About/Actionnaire.webp';
import TextBanner from '../../Components/TextBanner';
import ContactForm from '../../Components/ContactForm';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Actionnaire = () => {
  const { t } = useTranslation('about');

  const sendEmail = async (formData) => {
    try {
      // const response = await fetch('http://localhost:3002/send-email', {
      const response = await fetch('https://osqvaiaq1d.execute-api.us-east-1.amazonaws.com/prod/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Echec de l\'envoi de l\'email');
      }
  
      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email:', error);
    }
  };

  const RelationOptions = [
    { value: "Actionnaire", text: t('actionnaire.relationOptions.0.text') },
    { value: "Actionnaire", text: t('actionnaire.relationOptions.1.text') },
    { value: "Actionnaire", text: t('actionnaire.relationOptions.2.text') },
    { value: "Actionnaire", text: t('actionnaire.relationOptions.3.text') },
    { value: "Actionnaire", text: t('actionnaire.relationOptions.4.text') },
    { value: "Actionnaire", text: t('actionnaire.relationOptions.5.text') },
    { value: "Actionnaire", text: t('actionnaire.relationOptions.6.text') }
  ];
  
    return (
      <>
        <Helmet>
            <title>Forgez l'Avenir avec Nous : Opportunités d'Investissement</title>
            <meta name="title" content={t('actionnaire.pageMetaTitle')}/>
            <meta name="description" content={t('actionnaire.pageMetaDescription')} />
            <link rel="canonical" href="https://be-lifer.com/rejoignez-nous/devenez-actionnaire"/>
        </Helmet>
        <div>
          <Encart 
            title={t('actionnaire.encartTitle')}
            content={t('actionnaire.encartContent')}
            image={Image}
          />
          <TextBanner
            title={t('actionnaire.textBannerTitle1')}
            content={t('actionnaire.textBannerContent1')}
            level="h2"
          />
          <TextBanner
            title={t('actionnaire.textBannerTitle2')}
            content={t('actionnaire.textBannerContent2')}
            level="h3"
          />
          <ContactForm 
            defaultMessagePlaceholder={t('actionnaire.contactFormPlaceholder')}
            defaultRelation={t('actionnaire.contactFormDefaultRelation')}
            options={RelationOptions}
            sendEmail={sendEmail}
          />
        </div>
      </>
  );
};

export default Actionnaire;