import React from 'react';
import '../Style/UsBanner.css'; 
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageHeader = ({ title, text, imageUrl, link, level = "h2" }) => {
  const { t } = useTranslation('common');
  const TitleTag = level;
  return (
    <div className="page-header">
      <div className="header-content">
      <TitleTag> {title} </TitleTag> 
        <p>{text}</p>
        <Link to={link} className="About-button">
          <button className="About-button">{t('pageHeader.buttonText')}</button>
        </Link>
      </div>
      <img src={imageUrl} alt="join-us" className="header-image" />
    </div>
  );
};

export default PageHeader;
