import React from 'react';
// import './ProductCard.css'; // Assure-toi d'avoir un fichier CSS avec le style approprié

const ProductCard = ({ title, price, features, monthlyPrice, trialPeriod, link, isAnnual, backgroundColor }) => {
    return (
      <div className="product-card" style={{ backgroundColor }}>
        <div className="product-header">
          <h2>{title}</h2>
          <p className="price">{isAnnual ? price + ' €/an' : price + ' €/mois'}</p>
        </div>
        <ul className="features-list">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
        <div className="product-footer">
          <button onClick={() => window.location.href = link}>Acheter maintenant</button>
        </div>
        {monthlyPrice && (
          <p className="monthly-price">
            Ou achat au prix de {monthlyPrice} €/mois
          </p>
        )}
        {trialPeriod && (
          <p className="trial-period">
            Essai gratuit pendant {trialPeriod}
          </p>
        )}

      </div>
    );
  };

const SubscriptionOptions = () => {
  const products = [
    {
      title: 'Life Personnal',
      price: '49,00',
      features: [
        'Pour une seul personne',
        "Connecter jusqu'à 5 appereils simultanément",
        'Utilisation Web (PC, Mac, smartphone et tablettes',
        "1 To de stockage Cloud",
        "Données et sécurité des appareils",
        "Support standard"
      ],
      monthlyPrice: '5,00',
      trialPeriod: '3 mois',
      link: '#', // Mettre le lien réel ici
      isAnnual: true,
      backgroundColor: '#f0f0f0' // Ou toute autre couleur que tu veux
    },
    {
        title: 'Life Famille',
        price: '99,00',
        features: [
            "Connecter jusqu'à 5 appereils simultanément",
            'Utilisation Web (PC, Mac, smartphone et tablettes',
            "3 To de stockage Cloud",
            "Données et sécurité des appareils",
            "Support standard"
        ],
        monthlyPrice: '10,00',
        trialPeriod: '3 mois',
        link: '#', // Mettre le lien réel ici
        isAnnual: true,
        backgroundColor: '#f0f0f0' // Ou toute autre couleur que tu veux
      },
      {
        title: 'Life Personnal Plus',
        price: '99,00',
        features: [
            'Pour une seul personne',
            "Connecter jusqu'à 5 appereils simultanément",
            'Utilisation Web (PC, Mac, smartphone et tablettes',
            "3 To de stockage Cloud",
            "Données et sécurité des appareils",
            "Bénéficiez des nouvelles fonctionnalités en avant-première",
            "Faire partie de life influence",
            "Support personnalisé"
          // Ajoute toutes les fonctionnalités ici...
        ],
        link: '#', // Mettre le lien réel ici
        monthlyPrice: '10,00',
        trialPeriod: '1 mois',
        isAnnual: true,
        backgroundColor: '#f0f0f0' // Ou toute autre couleur que tu veux
      },
      {
        title: 'Life Famille Plus',
        price: '149,00',
        features: [
            "Connecter jusqu'à 5 appereils simultanément",
            'Utilisation Web (PC, Mac, smartphone et tablettes',
            "5 To de stockage Cloud",
            "Données et sécurité des appareils",
            "Bénéficiez des nouvelles fonctionnalités en avant-première",
            "Faire partie de life influence",
            "Support personnalisé"
        ],
        monthlyPrice: '15,00',
        trialPeriod: '1 mois',
        link: '#', // Mettre le lien réel ici
        isAnnual: true,
        backgroundColor: '#f0f0f0' // Ou toute autre couleur que tu veux
      },
  ];

  return (
    <div className="subscription-section">
        <div className="subscription-header">
            <p>
            Les offres ci-dessous sont prévisionnelles et indiquent les futurs prix de mise sur le marché. Le logiciel n'est pas encore disponible au grand public.
            </p>
        </div>
        <div className="subscription-container">
        {products.map((product, index) => (
            <ProductCard key={index} {...product} />
        ))}
        </div>
        <div className="subscription-header">
            <p>
            L’abonnement est renouvelé automatiquement. Vous pouvez annuler votre abonnement à tout moment afin de mettre fin aux prélèvements.
            </p>
        </div>
    </div>

  );
};

export default SubscriptionOptions;
