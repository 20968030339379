import React, { useState } from 'react';
import TextBanner from '../../Components/TextBanner';
import UsBanner from '../../Components/Usbanner';
import HeroBanner from '../../Components/HeroBanner';
import BackImg from '../../Images/Accueil/Background.webp';
import YouTubeVideo from '../../Components/Youtubevideo';
import { Helmet } from 'react-helmet';
import Encart from '../../Components/Encart';
import Image from '../../Images/Produit/Jeunesse.webp';
import { useTranslation } from 'react-i18next';

import '../../Style/Produit.css';

const Jeune = () => {
    const { t } = useTranslation('solution');
    return (
        <>
            <Helmet>
                <title>{t('jeune.pageTitle')}</title>
                <meta name="title" content={t('jeune.pageMetaTitle')} />
                <meta name="description" content={t('jeune.pageMetaDescription')} />
                <link rel="canonical" href="https://be-lifer.com/Solution/Nos-solutions-pour-les-jeunes" />
            </Helmet>
            <div>
                <Encart 
                    title={t('jeune.encartTitle')}
                    content={t('jeune.encartContent')}
                    image={Image}
                />
                <TextBanner
                    title={t('jeune.textBannerTitle1')}
                    content={t('jeune.textBannerContent1')}
                />
                <h2>{t('jeune.sectionTitle1')}</h2>
                <TextBanner
                    content={t('jeune.textBannerContent2')}
                />
                <YouTubeVideo videoId="WR6OfQ-DLeI" />
                <h2>{t('jeune.sectionTitle2')}</h2>
                <TextBanner
                    content={t('jeune.textBannerContent3')}
                />
                <YouTubeVideo videoId="Mdq-J1PGGfI" />
                <h2>{t('jeune.sectionTitle3')}</h2>
                <TextBanner
                    content={t('jeune.textBannerContent4')}
                />
                <YouTubeVideo videoId="vvG89sHxO7w" />
                <TextBanner
                    title={t('jeune.textBannerTitle2')}
                    content={t('jeune.textBannerContent5')}
                />
                <TextBanner
                    title={t('jeune.textBannerTitle3')}
                    content={t('jeune.textBannerContent6')}
                />
                <TextBanner
                    title={t('jeune.textBannerTitle4')}
                    content={t('jeune.textBannerContent7')}
                />
                <TextBanner
                    title={t('jeune.textBannerTitle5')}
                    content={t('jeune.textBannerContent8')}
                />
                <TextBanner
                    title={t('jeune.textBannerTitle6')}
                    content={t('jeune.textBannerContent9')}
                />
                <HeroBanner
                    title={t('jeune.heroBannerTitle')}
                    subtitle={t('jeune.heroBannerSubtitle')}
                    buttonText={t('jeune.heroBannerButtonText')}
                    buttonLink={t('jeune.heroBannerButtonLink')}
                    backgroundImage={BackImg}
                />
            </div>
        </>
    );
};

export default Jeune;