import React from 'react';
import PropTypes from 'prop-types';
import '../Style/UsBanner.css';

const AdvantageItem = ({ icon, title, description, level = "h3" }) => {
  const TitleTag = level;
  
  return ( 
    <div className="advantage-item">
      <img src={icon} alt={title} className="advantage-icon" />
      <TitleTag className="advantage-title">{title}</TitleTag>
      <p className="advantage-description">{description}</p>
    </div>
  );
};
  

AdvantageItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Advantages = ({ advantagesList }) => (
  <div className="advantages-container">
    {advantagesList.map((advantage, index) => (
      <AdvantageItem key={index} {...advantage} />
    ))}
  </div>
);

Advantages.propTypes = {
  advantagesList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Advantages;
