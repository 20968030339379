import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Encart from '../../Components/Encart';
import Image from '../../Images/Produit/Eco.webp';
import TestBanner from '../../Components/TestBanner';
import Features from '../../Components/FeatureSection';
import slide1 from '../../Images/Produit/EcoScreen2.webp'; 
import slide2 from '../../Images/Produit/EcoScreen3.webp'; 
import BackEco from '../../Images/Produit/EcoScreen.webp';
import Accordion from '../../Components/Accordion';
import TextBanner from '../../Components/TextBanner';
import HeroBanner from '../../Components/HeroBanner';
import Carousel from '../../Components/Carousel';
import ImageDomicile from '../../Images/Produit/Domicile.webp';
import ImageFinance from '../../Images/Produit/Finance.webp';
import ImageAdmin from '../../Images/Produit/Admin.webp';
import ImageEco from '../../Images/Produit/Eco.webp';
import ImagePerso from '../../Images/Produit/Perso.webp';
import ImagePro from '../../Images/Produit/Pro.webp';
import ImageSocial from '../../Images/Produit/Social.webp';
import ImageTime from '../../Images/Produit/Time.webp';
import ImageGeneral from '../../Images/Produit/General.webp';
import MonCarrousel from '../../Components/MonCarrousel';
import Advantages from '../../Components/Advantage';
import Impact from '../../Images/Produit/Impact.webp';
import Bienetre from '../../Images/Produit/Bienetre.webp';
import Solidarite from '../../Images/Produit/Solidarite.webp';
import '../../Style/Produit.css';

const Eco = () => {
  const advantagesList = [
    {
      icon: Impact,
      title: 'Impact',
      description: "Contribuez activement à l'écologie en accédant facilement à des options de compensation carbone via l'application, permettant de minimiser votre bilan carbone et lutter efficacement contre le réchauffement climatique.",
    },
    {
      icon: Bienetre,
      title: 'Bien-être',
      description: "Utilisez notre carte interactive pour trouver des produits frais et locaux, favorisant ainsi une consommation responsable et saine, et réduisez votre impact sur l'environnement par des choix alimentaires plus éclairés.",
    },
    {
      icon: Solidarite,
      title: 'Solidarité',
      description: "Rejoignez un réseau d'utilisateurs engagés pour échanger des idées et vous soutenir mutuellement dans la quête d'un mode de vie durable, renforçant la communauté autour de pratiques environnementales bénéfiques.",
    },
  ];

  const pageData = {
    title: 'NOS AUTRES SOLUTIONS',
    items: [
      {
        imageUrl: ImageSocial,
        title: 'Les relations sociales',
        link: '/Solution/Gérez-vos-relations'
      },
      {
        imageUrl: ImageTime,
        title: 'Le temps',
        link: '/Solution/Gérez-votre-temps'
      },
      {
        imageUrl: ImageAdmin,
        title: "L'administrative",
        link: '/Solution/Gérez-votre-vie-administrive'
      },
      {
        imageUrl: ImagePro,
        title: 'La carrière pro',
        link: '/Solution/Gérez-votre-carrière-pro'
      },
      {
        imageUrl: ImagePerso,
        title: 'La vie perso',
        link: '/Solution/Gérez-votre-santé-sport-alimentation-spiritualité'
      },
      {
        imageUrl: ImageDomicile,
        title: 'Le domicile',
        link: '/Solution/Gérez-votre-immobilier-et-vos-véhicules'
      },
      {
        imageUrl: ImageFinance,
        title: "Les finances",
        link: '/Solution/Gérez-vos-finances'
      },
      {
        imageUrl: ImageGeneral,
        title: "L'intégration",
        link: '/Solution'
      },
    ]
  };

  const featureData = [
    {
      title: "Moniteur Écologique",
      content: "Suivez votre consommation d'eau, d'électricité et de gaz en temps réel via une connexion aux compteurs intelligents. Obtenez des analyses détaillées avec graphiques et rapports périodiques pour mieux comprendre votre consommation et son impact environnemental.",
      link: "/Solution/Gérez-votre-empreinte-carbone",
    },
    {
      title: "Conseiller Vert",
      content: "Obtenez des suggestions écologiques basées sur vos habitudes. Par exemple, le système peut recommander des ampoules LED plus efficaces en remplacement de vos ampoules actuelles pour une consommation d'énergie réduite et moins d'émissions de CO2.",
      link: "/Solution/Gérez-votre-empreinte-carbone",
    },
    {
      title: "Guide Zéro Déchet",
      content: "Recevez des conseils pour diminuer vos déchets, y compris des listes de produits durables, des recettes pour créer vos propres produits ménagers et des astuces pour réduire les emballages, aidant à réduire votre empreinte écologique.",
      link: "/Solution/Gérez-votre-empreinte-carbone",
    },
    {
      title: "Compensateur Carbone",
      content: "Obtenez une estimation de votre empreinte carbone basée sur vos déplacements et consommation d'énergie, avec des suggestions pour la compenser, comme la plantation d'arbres ou l'utilisation de transports en commun, contribuant à la réduction des émissions de gaz à effet de serre.",
      link: "/Solution/Gérez-votre-empreinte-carbone",
    },
    {
      title: "Éducateur Planétaire",
      content: "Accédez à des articles, vidéos et autres ressources éducatives pour rester informé sur les problèmes environnementaux actuels et découvrir comment y contribuer positivement, améliorant votre efficacité énergétique et réduisant votre impact climatique.",
      link: "/Solution/Gérez-votre-empreinte-carbone"
    },
  ];

  const slides = [
    { src: slide1, type: 'img' },
  ];

  const slides2 = [
    { src: slide2, type: 'img' },
  ];

    return (
      <div>
        <Helmet>
          <title>Be-lifer, Préserver l'Environnement : Adoptez un Mode de Vie Durable</title>
          <meta name="title" content="Be-lifer, Préserver l'Environnement : Adoptez un Mode de Vie Durable"/>
          <meta name="description" content="Découvrez comment réduire votre empreinte carbone, minimiser les émissions de gaz à effet de serre, et contribuer à la lutte contre le réchauffement climatique avec nos outils et conseils pour un mode de vie durable et responsable. Engagez-vous dans la préservation de l'environnement grâce à des solutions éco-responsables." />
          <link rel="canonical" href="https://be-lifer.com/Solution/Gérez-votre-empreinte-carbone" />
          <meta name="author" content="Be-lifer"/>
          <meta name="robots" content="index, follow"/>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
          <meta name="language" content="French"/>
          <meta name="revisit-after" content="7 days"/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content="Be-Lifer : Adoptez un Mode de Vie Durable"/>
          <meta property="og:description" content="Découvrez comment réduire votre empreinte carbone, minimiser les émissions de gaz à effet de serre, et contribuer à la lutte contre le réchauffement climatique avec nos outils et conseils pour un mode de vie durable et responsable. Engagez-vous dans la préservation de l'environnement grâce à des solutions éco-responsables."/>
          <meta property="og:url" content="https://www.be-lifer.com/Solution/Gérez-votre-empreinte-carbone"/>
          <meta property="og:site_name" content="Be-Lifer"/>
        </Helmet>
        <Encart 
          title="La préservation de l'environnement" 
          content="La préservation de l'environnement est une symphonie de respect et de responsabilité, où chaque geste et chaque initiative résonnent comme un engagement envers l'excellence et l'authenticité de notre planète. Nos actions pour protéger et chérir la nature forment un tableau vivant, reflétant une harmonie sans équivalent entre l'homme et son environnement, un héritage précieux pour les générations futures." 
          image={Image}
        />
        <TestBanner />
        <TextBanner
          title = "Favoriser une vie plus écologique et durable"
          content = "Adoptez un mode de vie durable avec nos outils conçus pour réduire votre empreinte carbone et vous guider vers une consommation responsable. Notre plateforme enrichit votre quotidien d'analyses pointues et de conseils pratiques sur la réduction des gaz à effet de serre et la consommation énergétique, tout en s'intégrant harmonieusement avec d'autres modules pour une gestion écologique holistique de votre environnement. Prenez des décisions éclairées qui favorisent la préservation de notre planète, tout en embrassant une existence respectueuse de l'écosystème."
          level = "h2"
        />
        
        <Carousel slides={slides} />
        <Advantages advantagesList={advantagesList} level="h3" /> 
        <TestBanner />
        <Carousel slides={slides2} />
        <TestBanner />
        <h4>Des solutions conçues pour vous</h4>
        <Features features={featureData} />
        <TestBanner />
        <HeroBanner
          title="Diminuez votre empreinte, préservez notre futur"
          subtitle="Engagez-vous dans des pratiques qui réduisent significativement les émissions de gaz à effet de serre, améliorent l'utilisation des énergies renouvelables, et favorisent une gestion durable des ressources naturelles, tout en contribuant à lutter contre le réchauffement climatique."
          buttonText="Démarrer gratuitement"
          buttonLink="https://be-lifer.com/rejoignez-nous/Devenez-Testeur"
          backgroundImage={BackEco}
        />
        
        <MonCarrousel title={pageData.title} items={pageData.items} visibleItemsCount={3}  />
      </div>
    );
};

export default Eco;