import React, { useState } from 'react';
import TextBanner from '../../Components/TextBanner';
import Offres from '../../Components/Offres'
import Features from '../../Components/FeatureSection';
import Dashboard from '../../Components/Dashboard';
import LifeLogo from '../../Images/lifelogo.webp'
import HeroBanner from '../../Components/HeroBanner';
import BackImg from '../../Images/Accueil/Background.webp';

import '../../Style/SubOption.css';

const Offre = () => {
    const dashboardItems = [
        {
          title: 'Life Famille 2025',
          description: "Achat définitif, version 2025, support Life inclus pendants les 60 premiers jours sans frais supplémentaires",
          buttonText: 'En savoir plus',
          buttonLink: '/',
          imageUrl: LifeLogo,
        },
      ];

    const featureData = [
        {
          title: "Sécurité Simplifiée",
          content: "Assurez la protection de vos données personnelles avec une sécurité proactive. Notre solution offre une surveillance constante des menaces, des notifications immédiates en cas de risques potentiels, et des conseils sur mesure pour garantir votre tranquillité d'esprit.",
          link: "/",
        },
        {
          title: "Outils de Productivité Avancés",
          content: "Maximisez votre efficacité avec des outils intuitifs pour gérer vos finances et tâches personnelles. Travaillez de manière fluide, en ligne comme hors ligne, avec une suite d'outils conçus pour une collaboration et une organisation sans effort.",
          link: "/",
        },
        {
          title: "Stockage Cloud Sécurisé et Personnel",
          content: "Gardez vos documents les plus importants et vos souvenirs à portée de main. Stockez, partagez et éditez avec aisance dans un espace cloud privé, alliant capacité et facilité d'utilisation pour une tranquillité d'esprit absolue.",
          link: "/",
        },
        {
          title: "Optimiser Votre Quotidien",
          content: "Réduisez le temps consacré à la gestion de vos activités quotidiennes grâce à des outils intelligents conçus pour organiser votre emploi du temps et vos rappels, vous permettant ainsi de profiter davantage de chaque journée.",
          link: "/",
        },
        {
          title: "Centralisez vos vies pour une efficacité sans frontières",
          content: "Simplifiez votre existence avec un écosystème numérique qui centralise votre vie personnelle. Quelle que soit la marque ou le modèle de votre appareil, nos outils garantissent une transition transparente et sécurisée de vos données. Avec notre solution, changez de téléphone ou d'appareil sans jamais perdre une miette de votre univers numérique. Toute votre vie, accessible en un clic, où que vous soyez.",
          link: "/General",
        },
        {
          title: "Gestion financière personnelle pour bâtir Votre empire",
          content: "Prenez le contrôle de votre avenir financier avec des outils de gestion avancés. Notre solution vous permet de suivre vos dépenses, d'optimiser vos économies et d'investir intelligemment, posant ainsi les fondations solides pour bâtir votre empire personnel. Planifiez, projetez, et réalisez vos ambitions financières avec clarté et confiance.",
          link: "/Finance",
        },
        {
          title: "Durabilité et gestion de l'empreinte carbone",
          content: "Agissez pour un avenir plus vert grâce à des outils qui vous aident à mesurer et à gérer votre empreinte carbone. Notre application intègre la durabilité dans votre routine quotidienne, vous offrant des analyses et des recommandations pour réduire votre impact sur l'environnement tout en optimisant l'efficacité de vos ressources.",
          link: "/Eco",
        },
      ];


    return (
        <div>
            <TextBanner
            title = "Optimisez votre vie" 
            content="Avec Life, découvrez une solution élégante qui combine une protection en ligne robuste, un stockage Cloud fiable et des applications novatrices, le tout intégré dans une application unique et intuitive."
            /> 
            <Offres />
            <TextBanner
            title = "Découvrez les autres avantages de life " 
            />
            <Features features={featureData} /> 
            <TextBanner
            content=""
            /> 
            <Dashboard items={dashboardItems} />
            <TextBanner
            content=""
            /> 
            <HeroBanner
            title="Prenez le contrôle de votre vie"
            subtitle="Rejoignez Life et réalisez vos rêves"
            buttonText="Démarrer gratuitement"
            buttonLink="https://be-lifer.com/Testeur"
            backgroundImage={BackImg}
            />
      </div>
    );
  };
  
  export default Offre;