import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/About/Partenaire.webp';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import TextBanner from '../../Components/TextBanner';

import ContactForm from '../../Components/ContactForm';


const Partenaire = () => {
  const { t } = useTranslation('about');
  const featureData = [
    {
      title: t('partenaire.featureData.0.title'),
      content: t('partenaire.featureData.0.content'),
      link: "/Finance",
    },
    {
      title: t('partenaire.featureData.1.title'),
      content: t('partenaire.featureData.1.content'),
      link: "/Finance",
    },
    {
      title: t('partenaire.featureData.2.title'),
      content: t('partenaire.featureData.2.content'),
      link: "/Finance",
    },
    {
      title: t('partenaire.featureData.3.title'),
      content: t('partenaire.featureData.3.content'),
      link: "/Finance",
    },
    {
      title: t('partenaire.featureData.4.title'),
      content: t('partenaire.featureData.4.content'),
      link: "/Finance",
    },
    {
      title: t('partenaire.featureData.5.title'),
      content: t('partenaire.featureData.5.content'),
      link: "/Finance",
    },
    {
      title: t('partenaire.featureData.6.title'),
      content: t('partenaire.featureData.6.content'),
      link: "/Finance",
    },
    {
      title: t('partenaire.featureData.7.title'),
      content: t('partenaire.featureData.7.content'),
      link: "/Finance",
    }
  ];

  const sendEmail = async (formData) => {
    try {
      const response = await fetch('https://osqvaiaq1d.execute-api.us-east-1.amazonaws.com/prod/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Échec de l'envoi de l'email: ${errorText}`);
      }
  
      const result = await response.json();
        alert(`Message envoyé avec succès: ${result.message}`);
      } catch (error) {
        console.error("Erreur lors de l'envoi de l'email:", error);
        alert(`Erreur lors de l'envoi de l'email: ${error.message}`);
      }
  };

  const RelationOptions = [
    { value: "Partenaire", text: t('partenaire.relationOptions.0.text') },
    { value: "Partenaire", text: t('partenaire.relationOptions.1.text') },
    { value: "Partenaire", text: t('partenaire.relationOptions.2.text') },
    { value: "Partenaire", text: t('partenaire.relationOptions.3.text') },
    { value: "Partenaire", text: t('partenaire.relationOptions.4.text') }
  ];
  

    return (
      <>
        <Helmet>
            <title>{t('partenaire.pageTitle')}</title>
            <meta name="title" content={t('partenaire.pageMetaTitle')}/>
            <meta name="description" content={t('partenaire.pageMetaDescription')} />
            <link rel="canonical" href="https://be-lifer.com/rejoignez-nous/devenez-partenaire"/>
        </Helmet>
        <div>
          <Encart 
            title={t('partenaire.encartTitle')}
            content={t('partenaire.encartContent')}
            image={Image}
          />
          <TextBanner
            title={t('partenaire.textBannerTitle1')}
            content={t('partenaire.textBannerContent1')}
            level="h2"
          />
          <TextBanner
            title={t('partenaire.textBannerTitle2')}
            content={t('partenaire.textBannerContent2')}
            level="h3"
          />
          <ContactForm 
            defaultMessagePlaceholder={t('partenaire.contactFormPlaceholder')}
            defaultRelation={t('partenaire.contactFormDefaultRelation')}
            options={RelationOptions}
            sendEmail={sendEmail}
          />
      </div>
    </>
  );
};

export default Partenaire;