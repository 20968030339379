import React from 'react';
import '../Style/Banner.css'; 
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TestBanner = () => {
  const { t } = useTranslation('testbanner');

  return (
    <div className="banner">
      <div className="banner-text">
        {t('testBanner.text')}
      </div>
      <Link to="/rejoignez-nous/Devenez-Testeur" className="banner-button">
        <button className="banner-button">
          {t('testBanner.buttonText')}
        </button>
      </Link>
    </div>
  );
};

export default TestBanner;