import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../Images/lifelogo.webp';
import UserIcon from '../Images/Icon/User.webp';
import hamburger from '../Images/Icon/hamburger.webp';
import LanguageSelector from './LanguageSelector';
import SearchIcon from '../Images/Icon/Search.webp';
import FrenchFlag from '../Images/Drapeau/France.webp';
import '../Style/Header.css';



  function Header({ onUserIconClick }) {
    const { t } = useTranslation('header'); 
    // States pour gérer l'affichage des sous-menus
    const [showProduitDropdown, setShowProduitDropdown] = useState(false);
    const [searchExpanded, setSearchExpanded] = useState(false); 
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Cette fonction bascule l'affichage de la barre de recherche
    const toggleSearch = (event) => {
      event.preventDefault(); // Empêche le comportement par défaut du lien
      setSearchExpanded(prev => !prev);
    };
      // Empêche l'événement de clic de se propager
    const handleUserIconClick = (event) => {
      event.stopPropagation();
     onUserIconClick();
    };

    const toggleMenu = () => {
      setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen);
    };

    return (
      <header className="header">
        <div className="logo-section">
          <Link to="/" className="logo-link">
            <img src={logo} alt="Company Logo" className="CompanyLogo" />
          </Link>
        </div>
        {searchExpanded ? (
          <div className="search-expanded">
            <input type="text" placeholder="Recherche" autoFocus />
            <button onClick={toggleSearch} className="search-close">X</button>
            <button type="submit">{t('header.searchSubmit')}</button>
          </div>
        ) : (
          <>
            <nav className={`main-navigation ${isMenuOpen ? 'show' : ''} ${searchExpanded ? 'hide' : ''}`}>
              <ul>
                <li className="nav-section" 
                    onMouseEnter={() => setShowProduitDropdown(true)} 
                    onMouseLeave={() => setShowProduitDropdown(false)}>
                  <Link to="/Solution">{t('header.products')}</Link>
                  {showProduitDropdown && (
                    <div className="dropdown-content">
                      <div className="dropdown-section">
                        <Link to="/Solution/Présentation-solution">{t('header.presentation')}</Link>
                          {/* <Link to="/Finance">Gestion optimales des finances personnelles</Link>
                          <Link to="/Time">Optimiser la gestion du temps</Link>
                          <Link to="/Social">Améliorer les interactions sociales</Link>
                          <Link to="/Admin">Simplifier les tâches administratives</Link>
                          <Link to="/Pro">Gestion de la carrière professionnelle</Link>
                          <Link to="/Eco">Favoriser une vie plus écologique et durable</Link>
                          <Link to="/Perso">Unifier bien-être personnel: corps, esprit, loisirs, et spiritualité.</Link>
                          <Link to="/Domicile">Assurer une gestion efficace des besoins et tâches domestiques.</Link> */}
                          {/* Plus de liens si nécessaire */}
                      </div>
                      <div className="dropdown-section">
                        <h4>{t('header.solutions')}</h4>
                          <Link to="/Solution/Nos-solutions-pour-les-jeunes">{t('header.forYouth')}</Link>
                          <Link to="/Solution/Nos-solutions-pour-les-familles">{t('header.forParents')}</Link>
                          <Link to="/Solution/Nos-solutions-pour-les-technophiles">{t('header.forTechLovers')}</Link>
                          {/* <Link to="/Admin">Administratif</Link>
                          <Link to="/Pro">Professionnelle</Link>
                          <Link to="/Eco">Développement durable</Link>
                          <Link to="/Perso">Bien-être personnel</Link>
                          <Link to="/Domicile">Foyer</Link> */}
                        {/* <h4>Tester et acheter</h4>
                          <Link to="/categorie2/produit1">Essais et démonstrations</Link> */}
                          {/* <Link to="/Offre">Offre</Link> */}
                      </div> 
                  </div>
                  )}
                </li>
                
                <li className="nav-section" 
                    onMouseEnter={() => setShowProduitDropdown(true)} 
                    onMouseLeave={() => setShowProduitDropdown(false)}>
                  <a>{t('header.serviceSupport')}</a>
                  {showProduitDropdown && (
                    <div className="dropdown-content">
                      <div className="dropdown-section">
                        <h4>{t('header.serviceSupport')}</h4>  
                          <Link to="https://onedrive.live.com/?id=7D8D5CA51FD1FAAA%217748&cid=7D8D5CA51FD1FAAA">{t('header.documentation')}</Link>
                          {/* <Link to="/produit2">Assistance de base</Link> */}
                          <Link to="/Notre-feuille-de-route">{t('header.roadmap')}</Link>
                          {/* <Link to="/Offre">Offre</Link> */}
                      </div>
                  </div>
                  )}
                </li>
                {/* <li className="nav-section"
                    onMouseEnter={() => setShowProduitDropdown(true)} 
                    onMouseLeave={() => setShowProduitDropdown(false)}>
                  <Link to="/communaute">Communauté</Link>
                  {showProduitDropdown && (
                    <div className="dropdown-content">
                      <div className="dropdown-section">
                          <Link to="/produit1">Questions/réponses</Link>
                          <Link to="/produit2">Blogs</Link>
                          <Link to="/produit3">Communauté</Link>
                      </div>
                    </div>
                  )}
                </li> */}
                <li className="nav-section" 
                    onMouseEnter={() => setShowProduitDropdown(true)} 
                    onMouseLeave={() => setShowProduitDropdown(false)}>
                  <Link to="/rejoignez-nous">{t('header.partners')}</Link>
                  {showProduitDropdown && (
                    <div className="dropdown-content">
                      <div className="dropdown-section">
                        <Link to="/rejoignez-nous/devenez-actionnaire">{t('header.investors')}</Link>
                        <Link to="/rejoignez-nous/devenez-partenaire">{t('header.partners')}</Link>
                        <Link to="/rejoignez-nous/devenez-collaborateur">{t('header.joinUs')}</Link>
                        <Link to="/rejoignez-nous/Devenez-Testeur">{t('header.tester')}</Link>
                      </div>
                  </div>
                  )}
                </li>
                <li className="nav-section" 
                    onMouseEnter={() => setShowProduitDropdown(true)} 
                    onMouseLeave={() => setShowProduitDropdown(false)}>
                  <a>{t('header.about')}</a>
                  {showProduitDropdown && (
                    <div className="dropdown-content">
                      <div className="dropdown-section"> 
                          <Link to="/à-propos">{t('header.aboutUs')}</Link>
                          {/* <Link to="/produit3">Témoignages</Link> */}
                          {/* <Link to="/produit4">Evenement</Link> */}
                          <Link to="/rejoignez-nous/devenez-actionnaire">{t('header.becomeShareholder')}</Link>
                          {/* <Link to="/produit6">Centre d'actualité</Link> */}
                          {/* <Link to="/produit7">Sécurité et fiabilité</Link> */}
                      </div>
                  </div>
                  )}
                </li>
              </ul>
            </nav>
            <div className="icon-section">
            <Link to="#" className="hamburger-menu" onClick={toggleMenu}>
                <img src={hamburger} alt="hamburger" className="burger" />
              </Link>
              {/* <Link to="#" className="logo-link" onClick={handleUserIconClick}>
                <img src={UserIcon} alt="User connection" className="icon" />
              </Link> */}
              {/* <Link to="/" onClick={toggleSearch} className="search-link">
                <img src={SearchIcon} alt="Recherche" className="icon" />
              </Link> */}
              <LanguageSelector />
            </div>
          </>
        )}
      </header>
    );
  }

  export default Header;
