import React from 'react';
import '../Style/Header.css';
import { Link } from 'react-router-dom';

function LoginPopup({ onClose }) {
    return (
      <div className="login-popup-overlay" onClick={onClose}>
        <div className="login-popup-content" onClick={e => e.stopPropagation()}>
          <button className="login-popup-close" onClick={onClose}>X</button>
          <h2>Accéder à votre compte Life</h2>
          <input type="email" placeholder="Adresse e-mail *" />
          <button className="login-popup-submit">Se connecter</button>
          <p>
            <Link to="#create-account">Créer votre compte Life</Link> | 
            <Link to="#why-account">Pourquoi un compte Life ?</Link>
          </p>
        </div>
      </div>
    );
  }
  
  export default LoginPopup;