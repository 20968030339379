import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Encart from '../../Components/Encart';
import Image from '../../Images/About/Entreprise.webp';

import Advantages from'../../Components/Advantage';
import Simplicite from '../../Images/Produit/Simplicité.webp';
import Integrite from '../../Images/Produit/integrite.webp';
import Securite from '../../Images/Produit/Securite.webp';
import Humain from '../../Images/Produit/Humain.webp';
import Coherance from '../../Images/Produit/Coherance.webp';

import UsBanner from '../../Components/Usbanner'

import Carousel from '../../Components/Carousel';
import slide1 from '../../Images/About/MindMapping.webp';
import slide2 from '../../Images/About/Mission.webp';

import TextBanner from '../../Components/TextBanner';

import ModulesFeat from '../../Components/ModulesFeat';
import AdminImg from '../../Images/Produit/AdminScreen.webp'; 
import DomicileImg from '../../Images/Produit/DomicileScreen.webp'; 
import TimeIconB from '../../Images/Accueil/TimeIconB.webp'; 
import TimeIconG from '../../Images/Accueil/TimeIconG.webp'; 
import PersoIconB from '../../Images/Accueil/PersoIconB.webp'; 
import PersoIconG from '../../Images/Accueil/PersoIconG.webp'; 
import AdminIconB from '../../Images/Accueil/AdminIconB.webp'; 
import AdminIconG from '../../Images/Accueil/AdminIconG.webp'; 
import DomIconB from '../../Images/Accueil/DomIconB.webp'; 
import DomIconG from '../../Images/Accueil/DomIconG.webp'; 
import EcoIconB from '../../Images/Accueil/EcoIconB.webp'; 
import EcoIconG from '../../Images/Accueil/EcoIconG.webp'; 
import FiIconB from '../../Images/Accueil/FiIconB.webp'; 
import FiIconG from '../../Images/Accueil/FiIconG.webp'; 
import GeIconB from '../../Images/Accueil/GeIconB.webp'; 
import GeIconG from '../../Images/Accueil/GeIconG.webp'; 
import ProIconB from '../../Images/Accueil/ProIconB.webp'; 
import ProIconG from '../../Images/Accueil/ProIconG.webp'; 
import SoIconB from '../../Images/Accueil/SoIconB.webp'; 
import SoIconG from '../../Images/Accueil/SoIconG.webp'; 
import PersoImg from '../../Images/Produit/PersoScreen.webp'; 
import ProImg from '../../Images/Produit/ProScreen.webp'; 
import SocialImg from '../../Images/Produit/SocialScreen.webp'; 
import EcoImg from '../../Images/Produit/EcoScreen2.webp'; 
import GeneralImg from '../../Images/Produit/GeneralScreen.webp'; 
import TimeImg from '../../Images/Produit/TimeScreen2.webp'; 
import placeholderImage1 from '../../Images/Produit/FinanceScreen.webp';

import Dashboard from '../../Components/Dashboard';
import heureux from '../../Images/About/Heureux.webp'
import alexis from '../../Images/About/Alexis.webp'
import timeImg from '../../Images/Produit/Time.webp'
import financeImg from '../../Images/Produit/Finance.webp'
import ecoImg from '../../Images/Produit/Eco.webp'

import { Helmet } from 'react-helmet';


const Aboutus = () => {
  const { t } = useTranslation('about');
  const dashboardItems = [
    {
      title: t('aboutus.textBannerTitle1'),
      description: t('aboutus.textBannerContent1'),
      buttonText: t('aboutus.textBannerTitle1'),
      buttonLink: '/Solution/Présentation-solution',
      imageUrl: heureux,
    },
    {
      title: t('aboutus.textBannerTitle2'),
      description: t('aboutus.textBannerContent2'),
      buttonText: t('aboutus.textBannerTitle2'),
      buttonLink: '/à-propos/histoire-de-life',
      imageUrl: alexis,
    },
  ];

  const dashboardItems2 = [
    {
      title: "Maîtrisez le Temps : Optimisez Chaque Instant",
      description: "Transformez chaque seconde en un moment de productivité et de satisfaction. Cette stratégie vise à vous aider à organiser votre temps efficacement, en mettant l'accent sur la pleine conscience et la réduction des distractions, pour une vie plus équilibrée et épanouie.",
      buttonText: "En savoir plus sur le temps",
      buttonLink: '/',
      imageUrl: timeImg,
    },
    {
      title: "Capitalisez sur Chaque Euro : Intelligence Financière au Quotidien",
      description: "Maximisez la valeur de chaque euro dépensé. Il s'agit d'adopter une approche de gestion financière avisée, concentrée sur des investissements judicieux et une consommation responsable, pour un avenir financier plus sécurisé et prospère.",
      buttonText: 'En savoir plus sur la capitalisation',
      buttonLink: '/erp-options',
      imageUrl: financeImg,
    },
    {
      title: "Éco-Action : Votre Empreinte Verte Personnelle",
      description: "Réduisez votre empreinte carbone et contribuez à un avenir plus durable. Cette démarche vous guide vers des choix éco-responsables dans la vie de tous les jours, comme l'adoption de pratiques de consommation d'énergie plus vertes et la réduction des déchets, pour un impact positif sur l'environnement.",
      buttonText: 'En savoir plus sur les éco-actions',
      buttonLink: '/erp-options',
      imageUrl: ecoImg,
    },
  ];

    const slides = [
        { src: slide1, type: 'img' },
    ];
    const slides2 = [
        { src: slide2, type: 'img' },
    ];


    const advantagesList = [
      {
        icon: Integrite,
        title: t('aboutus.advantages.0.title'),
        description: t('aboutus.advantages.0.description'),
      },
      {
        icon: Simplicite,
        title: t('aboutus.advantages.1.title'),
        description: t('aboutus.advantages.1.description'),
      },
      {
        icon: Securite,
        title: t('aboutus.advantages.2.title'),
        description: t('aboutus.advantages.2.description'),
      },
      {
        icon: Humain,
        title: t('aboutus.advantages.3.title'),
        description: t('aboutus.advantages.3.description'),
      },
      {
        icon: Coherance,
        title: t('aboutus.advantages.4.title'),
        description: t('aboutus.advantages.4.description'),
      },
    ];
  
    return (
      <>
        <Helmet>
          <title>{t('aboutus.pageTitle')}</title>
          <meta name="title" content={t('aboutus.pageMetaTitle')} />
          <meta name="description" content={t('aboutus.pageMetaDescription')} />
          <link rel="canonical" href="https://be-lifer.com/à-propos" />
        </Helmet>
        <div>
          <Encart 
            title={t('aboutus.encartTitle')} 
            content={t('aboutus.encartContent')} 
            image={Image}
          />
          <TextBanner
            title={t('aboutus.textBannerTitle1')}
            content={t('aboutus.textBannerContent1')}
            level="h2"
          />
          <TextBanner
            title={t('aboutus.textBannerTitle2')}
            level="h3"
          />
          <Dashboard items={dashboardItems} />
          <TextBanner
            title={t('aboutus.textBannerTitle3')}
            level="h4"
          />
          <Advantages advantagesList={advantagesList} level="h5" /> 
          <TextBanner
            title={t('aboutus.textBannerTitle4')}
            content={t('aboutus.textBannerContent2')}
            level="h6"
          />
          <Carousel slides={slides2}/>
          <TextBanner
            title={t('aboutus.textBannerTitle5')}
            content={t('aboutus.textBannerContent3')}
            level="h6"
            className='h7'
          />
          <TextBanner
            title={t('aboutus.textBannerTitle6')}
            content={t('aboutus.textBannerContent4')}
            level="h6"
            className='h8'
          />
        </div>
      </>
    );
  };
  
  export default Aboutus;