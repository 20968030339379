import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Encart from '../../Components/Encart';
import Image from '../../Images/About/AlexisBack.webp';

import TextBanner from '../../Components/TextBanner';
import Imgbanner from '../../Components/ImgBanner'; 
import Image2 from '../../Images/About/pere.webp';
import Image3 from '../../Images/About/Expériences.webp';
import Image4 from '../../Images/About/voyage.webp';
import Image5 from '../../Images/About/Naissance.webp';

const LifeHistory = () => {
  const { t } = useTranslation('about');

  return (
    <>
      <Helmet>
        <title>{t('lifeHistory.pageTitle')}</title>
        <meta name="description" content={t('lifeHistory.pageMetaDescription')} />
        <link rel="canonical" href="https://be-lifer.com/à-propos/histoire-de-life"/>
      </Helmet>
      <div>
        <Encart 
          title={t('lifeHistory.encartTitle')} 
          content={t('lifeHistory.encartContent')}
          image={Image}
        />
        <Imgbanner
          title={t('lifeHistory.imgBannerTitle1')}
          content={t('lifeHistory.imgBannerContent1')}
          image={Image2}
        />
        <Imgbanner
          title={t('lifeHistory.imgBannerTitle2')}
          content={t('lifeHistory.imgBannerContent2')}
          image={Image3}
        />
        <TextBanner
          title={t('lifeHistory.textBannerTitle1')}
          content={t('lifeHistory.textBannerContent1')}
        />
        <Imgbanner
          title={t('lifeHistory.imgBannerTitle3')}
          content={t('lifeHistory.imgBannerContent3')}
          image={Image4}
        />
        <Imgbanner
          title={t('lifeHistory.imgBannerTitle4')}
          content={t('lifeHistory.imgBannerContent4')}
          image={Image5}
        />
        <TextBanner
          title={t('lifeHistory.textBannerTitle2')}
          content={t('lifeHistory.textBannerContent2')}
        />
        <TextBanner
          title={t('lifeHistory.textBannerTitle3')}
          content={t('lifeHistory.textBannerContent3')}
        />
      </div>
    </>
  );
};

export default LifeHistory;

