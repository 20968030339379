import React from 'react';
import RoadmapPic from '../Images/Accueil/Roadmap.webp';
import '../Style/Roadmap.css'; 
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function RoadMapComponent() {
  const { t } = useTranslation('common');

  return (
    <div className="roadmap-container">
      <div>
        <img src={RoadmapPic} alt="Be-lifer Roadmap" className="roadmap-icon" />
      </div>
      <div className="roadmap-content">
        <div className='h6'>{t('roadmap.title')}</div>
        <p>{t('roadmap.description')}</p>
        <Link to="/Roadmap" className="roadmap-link">{t('roadmap.linkText')}</Link>
      </div>
    </div>
  );
}

export default RoadMapComponent;
