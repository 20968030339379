// src/LanguageSelector.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../Style/LanguageSelector.css'; // Crée un fichier CSS pour le style

const languages = [
  { code: 'en', name: 'English', flag: '🇺🇸' },
  { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
  { code: 'fr', name: 'Français', flag: '🇫🇷' },
  { code: 'fr-CH', name: 'Français Suisse', flag: '🇨🇭' },
  { code: 'it', name: 'Italiano', flag: '🇮🇹' },
  { code: 'de-CH', name: 'Deutsch Schweiz', flag: '🇨🇭' },
];

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLang(lng);
  };

  return (
    <div className="language-selector">
      <div className="selected-language">
        <span>{languages.find((lang) => lang.code === selectedLang)?.flag}</span>
        <select
          value={selectedLang}
          onChange={(e) => changeLanguage(e.target.value)}
          className="language-dropdown"
        >
          {languages.map((lang) => (
            <option key={lang.code} value={lang.code}>
              {lang.flag} {lang.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default LanguageSelector;
