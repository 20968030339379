import React from 'react';
import PropTypes from 'prop-types';
import '../Style/MonCarrousel.css';

class MonCarrousel extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
          imageUrl: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired // Ajoute la propriété pour l'URL
        })).isRequired,
        title: PropTypes.string
      };
      
    constructor(props) {
      super(props);
      this.state = {
        currentIndex: 0
      };
      this.carrouselRef = React.createRef();
      // Cela doit correspondre à la largeur définie dans ton CSS pour `.mon-carrousel-item`
      this.itemWidth = 300; // Remplacer par la largeur réelle de ton élément de carrousel
    }
  
  carrouselRef = React.createRef();

  goToItem = (index) => {
    // Assure-toi que 'this.itemWidth' et 'this.props.visibleItemsCount' sont bien définis
    const scrollX = index * this.itemWidth * this.props.visibleItemsCount;
    this.carrouselRef.current.scrollLeft = scrollX;
    this.setState({ currentIndex: index * this.props.visibleItemsCount });
  };

  navigateTo = (link) => {
    // Cette méthode mettra à jour la fenêtre ou l'historique avec le lien fourni
    window.location.href = link; // Ou utilise history.push(link) si tu utilises React Router
  };

  prevItem = () => {
    // ... ta logique pour définir le nouvel index
    const newScrollPosition = this.state.currentIndex * this.itemWidth;
    this.carrouselRef.current.scrollLeft = newScrollPosition;
  };
  
  nextItem = () => {
    // ... ta logique pour définir le nouvel index
    const newScrollPosition = this.state.currentIndex * this.itemWidth;
    this.carrouselRef.current.scrollLeft = newScrollPosition;
  };

  renderPagination() {
    const { items, visibleItemsCount } = this.props;
    const { currentIndex } = this.state;
    const pageCount = Math.ceil(items.length / visibleItemsCount);
    
    let dots = [];
    for (let i = 0; i < pageCount; i++) {
      dots.push(
        <button
          key={i}
          className={`dot ${currentIndex / visibleItemsCount === i ? 'active' : ''}`}
          onClick={() => this.goToItem(i)}
        />
      );
    }
    return <div className="mon-carrousel-pagination">{dots}</div>;
  }


  render() {
    const { currentIndex } = this.state;
    const { items, title } = this.props;
    const dots = items.map((_, idx) => (
        <button
          key={idx}
          className={`dot ${currentIndex === idx ? 'active' : ''}`}
          onClick={() => this.goToItem(idx)}
        />
      ));
    
    // Génère les éléments du carrousel à partir des props
    const carrouselItems = items.map((item, index) => (
        <div
            key={index}
            className="mon-carrousel-item"
            onClick={() => this.navigateTo(item.link)} 
        >
            <img src={item.imageUrl} alt={item.title} />
            <h3>{item.title}</h3>
        </div>
    ));

    return (
      <div className="mon-carrousel-container">
        <h2>{title}</h2>
        <div className="mon-carrousel" ref={this.carrouselRef}>
          {carrouselItems}
        </div>
        {this.renderPagination()}
        <div className="mon-carrousel-controls">
          {/* <button onClick={this.prevItem}>P</button>
          <button onClick={this.nextItem}>S</button> */}
        </div>
      </div>
    );
  }
}



export default MonCarrousel;
