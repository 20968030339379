import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/About/Partenaire.webp';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import TextBanner from '../../Components/TextBanner';

import ContactForm from '../../Components/ContactForm';

const ContactUs = () => {
  const { t } = useTranslation('about');
    const sendEmail = async (formData) => {
      try {
        const response = await fetch('https://osqvaiaq1d.execute-api.us-east-1.amazonaws.com/prod/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
      
          if (!response.ok) {
            throw new Error('Echec de l\'envoi de l\'email');
          }
    
        const result = await response.json();
        console.log(result.message);
      } catch (error) {
        console.error('Erreur lors de l\'envoi de l\'email:', error);
      }
    };
  
    const RelationOptions = [
      { value: "Partenaire", text: t('contactUs.relationOptions.0.text') },
      { value: "Partenaire", text: t('contactUs.relationOptions.1.text') },
      { value: "Partenaire", text: t('contactUs.relationOptions.2.text') },
      { value: "Partenaire", text: t('contactUs.relationOptions.3.text') }
    ];
    
  
    return (
      <>
        <Helmet>
          <title>{t('contactUs.pageTitle')}</title>
          <meta name="description" content={t('contactUs.pageMetaDescription')} />
          <link rel="canonical" href="https://be-lifer.com/Contactez-nous" />
        </Helmet>
        <div>
          <TextBanner
            title={t('contactUs.textBannerTitle')}
            content={t('contactUs.textBannerContent')}
            level="h1"
          />
          <ContactForm 
            defaultMessagePlaceholder={t('contactUs.contactFormPlaceholder')}
            defaultRelation={t('contactUs.contactFormDefaultRelation')}
            options={RelationOptions}
            sendEmail={sendEmail}
          />
        </div>
      </>
    );
  };
  
  export default ContactUs;