import React from 'react';
import '../Style/Banner.css'; 

const TextBanner = ({ title, content, level = "h2", className = "" }) => {
  const TitleTag = level;
  return (
    <div className="textbanner-container">
      <TitleTag className={`textbanner-title ${className}`}>{title}</TitleTag>
      <p className="textbanner-content">{content}</p>
    </div>
  );
};

export default TextBanner;