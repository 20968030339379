import React, { useState } from 'react';
import '../Style/ContactForm.css'; 
import { useTranslation } from 'react-i18next';

const ContactForm = ({ sendEmail, options, defaultRelation, defaultMessagePlaceholder }) => {
  const { t } = useTranslation('common');
  const [formData, setFormData] = useState({
    subject: '',
    message: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    relation: defaultRelation || t('contactForm.placeholders.relation'),
  });
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    try {
      await sendEmail(formData);
      setEmailSent(true);
      setFormData({ ...formData, subject: '', message: '', firstName: '', lastName: '', phone: '', email: '', relation: defaultRelation || 'Relation avec life *' });
    } catch (error) {
      console.error(t('contactForm.placeholders.errorMessage'), error);
      setErrorMessage(t('contactForm.placeholders.errorMessage'));
      setEmailSent(false);
    }
  };

  if (emailSent) {
    return <div>{t('contactForm.placeholders.successMessage')}</div>;
  }

  return (
    <div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form className="contact-form" onSubmit={handleSubmit}>
        <input type="text" name="firstName" required onChange={handleChange} placeholder={t('contactForm.placeholders.firstName')} />
        <input type="text" name="lastName" required onChange={handleChange} placeholder={t('contactForm.placeholders.lastName')} />
        <input type="tel" name="phone" required onChange={handleChange} placeholder={t('contactForm.placeholders.phone')}/>
        <input type="email" name="email" required onChange={handleChange} placeholder={t('contactForm.placeholders.email')} />
        <select name="subject" required onChange={handleChange}>
          <option value="">{t('contactForm.placeholders.subject')}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
        <textarea className='textarea'
          name="message" 
          required 
          onChange={handleChange} 
          placeholder={defaultMessagePlaceholder || t('contactForm.placeholders.message')} 
        />
        <select name="relation" value={formData.relation} required onChange={handleChange}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          ))}
        </select>
        <button type="submit">{t('contactForm.buttonText')}</button>
      </form>
    </div>
  );
};

export default ContactForm;
