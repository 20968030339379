import React, { useState } from 'react';
import Encart from '../../Components/Encart';
import Image from '../../Images/Produit/Domicile.webp';
import TestBanner from '../../Components/TestBanner';
import BackDomicile from '../../Images/Produit/DomicileBack.webp';
import Features from '../../Components/FeatureSection';
import slide1 from '../../Images/Produit/DomicileScreen.webp'; 
import slide2 from '../../Images/Produit/DomicileScreen2.webp'; 
import Accordion from '../../Components/Accordion';
import TextBanner from '../../Components/TextBanner';
import HeroBanner from '../../Components/HeroBanner';
import Carousel from '../../Components/Carousel';
import ImageTime from '../../Images/Produit/Time.webp';
import ImageFinance from '../../Images/Produit/Finance.webp';
import ImageAdmin from '../../Images/Produit/Admin.webp';
import ImageEco from '../../Images/Produit/Eco.webp';
import ImagePerso from '../../Images/Produit/Perso.webp';
import ImagePro from '../../Images/Produit/Pro.webp';
import ImageSocial from '../../Images/Produit/Social.webp';
import ImageGeneral from '../../Images/Produit/General.webp';
import MonCarrousel from '../../Components/MonCarrousel';
import Advantages from '../../Components/Advantage';
import Economie from '../../Images/Produit/Economie.webp';
import Prevention from '../../Images/Produit/Prevention.webp';
import Centralisation from '../../Images/Produit/Centralisation.webp';
import { Helmet } from 'react-helmet';
import '../../Style/Produit.css';

const Domicile = () => {
  const advantagesList = [
    {
      icon: Economie,
      title: 'Économie',
      description: "Surveillez votre consommation d'énergie en temps réel pour détecter les gaspillages et réduire les coûts.",
    },
    {
      icon: Prevention,
      title: 'Prévention',
      description: "Bénéficiez d'une planification automatique des entretiens pour prévenir les pannes inattendues.",
    },
    {
      icon: Centralisation,
      title: 'Centralisation',
      description: "Gardez tous vos documents de véhicule, biens ou immobiliers, tels que contrats, factures et certificats, organisés en un seul endroit numérique.",
    },
  ];

  const pageData = {
    title: 'NOS AUTRES SOLUTIONS',
    items: [
      {
        imageUrl: ImageSocial,
        title: 'Les relations sociales',
        link: '/Solution/Gérez-vos-relations'
      },
      {
        imageUrl: ImageTime,
        title: 'Le temps',
        link: '/Solution/Gérez-votre-temps'
      },
      {
        imageUrl: ImageAdmin,
        title: "L'administrative",
        link: '/Solution/Gérez-votre-vie-administrive'
      },
      {
        imageUrl: ImagePro,
        title: 'La carrière pro',
        link: '/Solution/Gérez-votre-carrière-pro'
      },
      {
        imageUrl: ImagePerso,
        title: 'La vie perso',
        link: '/Solution/Gérez-votre-santé-sport-alimentation-spiritualité'
      },
      {
        imageUrl: ImageEco,
        title: 'Notre envirronnement',
        link: '/Solution/Gérez-votre-empreinte-carbone'
      },
      {
        imageUrl: ImageFinance,
        title: "Les finances",
        link: '/Solution/Gérez-vos-finances'
      },
      {
        imageUrl: ImageGeneral,
        title: "L'intégration",
        link: '/Solution'
      },
    ]
  };

  const featureData = [
    {
      title: "Éco-Surveillant",
      content: "Suivez en temps réel votre consommation d'électricité, de gaz et d'eau. Des graphiques et tableaux détaillent votre consommation quotidienne, hebdomadaire ou mensuelle, aidant à identifier les opportunités d'économies.",
      link: "/Solution/Gérez-votre-immobilier-et-vos-véhicules",
    },
    {
      title: "Planificateur de Maintenance",
      content: "Programmez des rappels pour la maintenance régulière de chaque appareil ou équipement enregistré. Que ce soit pour des filtres d'air, des révisions de chaudière ou des remplacements d'ampoules, l'application veille à ce que rien ne soit oublié.",
      link: "/Solution/Gérez-votre-immobilier-et-vos-véhicules",
    },
    {
      title: "Liste d'achats",
      content: "Créez des listes d'achats pour vos besoins domestiques, organisables par pièce, type de produit ou magasin. Synchronisez-les facilement avec d'autres applications ou services de livraison.",
      link: "/Solution/Gérez-votre-immobilier-et-vos-véhicules",
    },
    {
      title: "Garage Numérique",
      content: "Enregistrez tous vos véhicules, des voitures aux vélos. Suivez les services, assurances, taxes, consommation de carburant et autres aspects pertinents pour chaque véhicule",
      link: "/Solution/Gérez-votre-immobilier-et-vos-véhicules",
    },
    {
      title: "Portfolio Immobilier",
      content: "Pour les propriétaires ou locataires, suivez tous les aspects de vos propriétés, incluant paiements hypothécaires, taxes foncières, assurances et dates de renouvellement de bail. Configurez des alertes pour des événements clés.",
      link: "/Solution/Gérez-votre-immobilier-et-vos-véhicules",
    },
    {
      title: "Mes animaux",
      content: "Suivez la santé et le bien-être de vos animaux de compagnie avec facilité. Enregistrez leurs vaccinations, rendez-vous vétérinaires, habitudes alimentaires, et activités quotidiennes. Recevez des rappels pour les soins réguliers, assurant ainsi leur santé optimale",
      link: "/Solution/Gérez-votre-immobilier-et-vos-véhicules",
    },
    {
      title: "Dashboard domicile",
      content: "Centralisez la gestion de votre quotidien avec notre tableau de bord : suivi de la santé animale, consommation d'énergie, maintenance des appareils, gestion des achats, suivi des véhicules et aspects immobiliers, le tout en un seul endroit pour une efficacité optimale.",
      link: "/Solution/Gérez-votre-immobilier-et-vos-véhicules",
    },
  ];

  const slides = [
    { src: slide1, type: 'img' },
  ];

  const slides2 = [
    { src: slide2, type: 'img' },
  ];

    return (
      <div>
        <Helmet>
          <title>Be-lifer, Gestion Domestique Intégrée avec Life</title>
          <meta name="title" content="Be-lifer, Gestion Domestique Intégrée avec Life"/>
          <meta name="description" content="Centralisez la gestion de votre domicile avec Life : efficacité, organisation, et maintenance simplifiée." />
          <link rel="canonical" href="https://be-lifer.com/Solution/Gérez-votre-immobilier-et-vos-véhicules"/>
        </Helmet>
        <Encart 
          title="Le domicile" 
          content="Les besoins et tâches domestiques sont le reflet d'un quotidien harmonieux et authentique, un ballet de gestes et d'actions qui tissent le confort et la sérénité de nos foyers. Chaque tâche accomplie est une célébration de l'ordre et de la tranquillité, une manifestation d'excellence dans l'art de créer un havre de paix et de bien-être, unique et sans équivalent dans le sanctuaire de nos vies." 
          image={Image}
        />
        <TestBanner />
        <TextBanner
          title = "Assurer une gestion efficace des besoins et tâches domestiques"
          content = "Centralisez la gestion de vos actifs avec notre plateforme tout-en-un, spécialement conçue pour les biens immobiliers, les véhicules, les appareils ménagers et plus encore. Améliorez l'efficacité énergétique de votre foyer, orchestrez les tâches ménagères avec précision et gardez le contrôle total sur la maintenance de vos équipements. Notre solution apporte une organisation sans faille et une optimisation intelligente, facilitant la vie domestique et renforçant la durabilité de chaque aspect de votre habitat."
        />
        
        <Carousel slides={slides} />
        <Advantages advantagesList={advantagesList} /> 
        <TestBanner />
        <Carousel slides={slides2} />
        <TestBanner />
        <h1>Des solutions conçues pour vous</h1>
        <Features features={featureData} />
        <TestBanner />
        <HeroBanner
          title="Harmonisez votre foyer"
          subtitle="Simplifiez votre quotidien"
          buttonText="Démarrer gratuitement"
          buttonLink="https://be-lifer.com/rejoignez-nous/Devenez-Testeur"
          backgroundImage={BackDomicile}
        />
        
        <MonCarrousel title={pageData.title} items={pageData.items} visibleItemsCount={3}  />
      </div>
    );
};

export default Domicile;